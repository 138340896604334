import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { axiosClient } from '../../../services/axiosClient';
import { BACKEND_URLS, ENDPOINT_HOST } from '../../../config';
import PaymentsHeader from '../../../components/headers/payement.header';
import toast from 'react-hot-toast';
import userService from '../../../services/user-service';
import { User } from '../../../types/user-profille-types';

function Payments() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(100)
  const [staffUsers, setStaffUsers] = useState<User[]>([])

  // Filter states
  const [filters, setFilters] = useState({
    collectedBy: "",
    status: "Completed",
    paymentType: "",
    phase: "",
    search: "",
    from: "",
    to: "",
  });

  const fetchStaff = async () => {
    try {
      const response = await userService.getStaff();
      setStaffUsers(response.data?.users)
    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  }

  const fetchAllEnrollments = async (page: number) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`${ENDPOINT_HOST + BACKEND_URLS.ORDERS}`, {
        params: {
          page,
          limit: perPage,
          ...filters,
        },
      });
      setData(response.data.data);
    } catch (error: any) {
      toast.error("Error fetching enrollments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    fetchAllEnrollments(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    fetchAllEnrollments(page);
  };

  const onFilterApply = () => {
    setResetPaginationToggle(true);
    fetchAllEnrollments(1);
  };

  const onChangeFilterText = (value: string, key: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const getCollectedByName = (row: any) => {
    const staffId = row?.enrollmentPayment?.length > 0 ? row?.enrollmentPayment[0]?.staffId : null
    if (staffId) {
      return staffUsers?.find((staffUser: User) => staffUser?.id === staffId)?.name || "NA"
    }
    return "NA"
  }

  const columns = [
    {
      name: "Payment Date",
      // selector: (row: any) => row?.enrollmentPayment?.length > 0 ? row?.enrollmentPayment[0]?.receivedOn : "NA", sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row?.enrollmentPayment?.length > 0 ? row?.enrollmentPayment[0]?.receivedOn?.split('T')[0] : "NA"} </div>,
    },
    {
      name: "User Name",
      selector: (row: any) => row.user.username, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.user.username} </div>,
    },
    {
      name: "Name",
      selector: (row: any) => row.user.name, sortable: true, cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.user.name || "NA"} </div>,
    },
    {
      name: "Course",
      selector: (row: any) => row.course.name, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.course.name} </div>,
    },
    {
      name: "Current End Date",
      selector: (row: any) => row.validTill?.split('T')[0], sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.validTill?.split('T')[0]} </div>,
    },
    {
      name: "Total Amount",
      selector: (row: any) => row.totalAmount, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.totalAmount} </div>,
    },
    {
      name: "Payment Type",
      // selector: (row: any) => row?.enrollmentPayment?.length > 0 ? row?.enrollmentPayment[0]?.mode : "NA", sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row?.enrollmentPayment?.length > 0 ? row?.enrollmentPayment[0]?.mode : "NA"} </div>,
    },
    {
      name: "Status",
      cell: (row: any) => (
        <span>
          {row?.enrollmentPayment?.some((payment: any) => payment?.enrollmentPayment?.status === "PENDING") ? 'Pending' : 'Completed'}
        </span>
      ),
      sortable: false,
    },
    { name: "Collected by", selector: getCollectedByName, sortable: true },
  ];

  useEffect(() => {
    fetchAllEnrollments(1); // Fetch data on mount
  }, []);

  useEffect(() => {
    fetchStaff();
  }, [])

  return (
    <div className='mx-3'>
      <h1>All Payments</h1>
      <PaymentsHeader
        onChangeFilterText={onChangeFilterText}
        onFilterApply={onFilterApply}
        userPermissions={['ad']} // Example permission, modify as needed
      />

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        noDataComponent="No Data Set"
        paginationServer
        highlightOnHover
        pointerOnHover
        onRowClicked={(row) => console.log("Row clicked:", row)}
        paginationResetDefaultPage={resetPaginationToggle}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        customStyles={{
          table: {
            style: {
              width: '90%',
              margin: 'auto'
            }
          }
        }}
      />
    </div>
  );
}

export default Payments;