import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PiCrownFill, PiCrownLight } from 'react-icons/pi';
import { IoMdAdd, IoMdClose } from 'react-icons/io';
import CourseService from '../../../services/ApiServices/course-service';
import SetService from '../../../services/ApiServices/set-service';
import { CourseSetItem, SetItem } from '../../../types/setTypes';
import { Button, Modal } from 'react-bootstrap';
import CustomCheckbox from '../../common/custom-checkbox';
import { getMediaUrl } from '../../../utils/helpers';
import toast from 'react-hot-toast';
import TriggerToolTip from '../../tooltip/tooltip-trigger';
import { FiCheckSquare } from 'react-icons/fi';
import { LuSquareDashed } from 'react-icons/lu';
import { RiDragMove2Line } from 'react-icons/ri';

type Props = {
    courseSets: CourseSetItem[];
};

const SetTabs = ({ courseSets }: Props) => {
    const [sets, setSets] = useState<SetItem[]>([]);
    const [addedIds, setAddedIds] = useState<number[]>(courseSets.map((set) => set.setId));
    const [updateEnrolled, setUpdateEnrolled] = useState(false);
    const [isDefault, setIsDefault] = useState(true);
    const [action, setAction] = useState<'add' | 'remove'>('add');
    const [addedSets, setAddedSets] = useState<CourseSetItem[]>(courseSets);
    const [query, setQuery] = useState<string>('');
    const [addedSetQuery, setAddedSetQuery] = useState<string>('');
    const { courseId } = useParams();
    const [show, setShow] = useState(false);
    const [currData, setCurrData] = useState<SetItem | CourseSetItem | null>(null);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
    const [isDraggable, setIsDraggable] = useState(false)

    const handleShow = () => setShow((prev) => !prev);

    useEffect(() => {
        SetService.getAll().then((res) => setSets(res.data.sets));
    }, []);

    const fetchCourseDetails = () => {
        if (courseId) {
            CourseService.getCourseById(+courseId).then((res) => {
                const course = res.data.course;
                setAddedSets(course.sets);
                setAddedIds(course.sets.map((set: CourseSetItem) => set.setId));
            });
        }
    };

    const filteredSets = sets
        .filter(
            (set) =>
                set.name.toLowerCase().includes(query.toLowerCase()) && !addedIds.includes(set.id)
        )
        .sort(
            (a, b) =>
                new Date(b?.updatedOn ?? '').getTime() - new Date(a?.updatedOn ?? '').getTime()
        ); // Sort by updatedOn in descending order

    const filteredAddedSets = addedSets.filter((set) =>
        set.name.toLowerCase().includes(addedSetQuery.toLowerCase())
    );

    const addSetToCourse = (set: CourseSetItem) => {
        const data = {
            setId: set.id,
            isPrimary: set?.isPrimary || false,
            isDefault: isDefault,
            order: set?.order,
            ...(updateEnrolled && { updateEnrolled }),
        };
        if (courseId) {
            CourseService.addSet(+courseId, data).then((res) => {
                setUpdateEnrolled(false);
                if (res.status === 'success') {
                    fetchCourseDetails();
                } else toast.error(res.message);
            });
        }
    };

    const removeSetFromCourse = (set: CourseSetItem) => {
        if (courseId) {
            CourseService.removeSet(+courseId, {
                setId: set.setId,
                ...(updateEnrolled && { updateEnrolled }),
            }).then((res) => {
                setUpdateEnrolled(false);
                if (res.status === 'success') {
                    fetchCourseDetails();
                } else toast.error(res.message);
            });
        }
    };

    const updateCourseSetStatus = (id: number, isPrimary: boolean) => {
        // const isOnePrimary = filteredAddedSets?.some((set) => set.isPrimary)
        // if (isPrimary && isOnePrimary) {
        //     toast.error('You can select only one primary course');
        //     return;
        // }

        const data = { courseSetId: id, isPrimary };
        CourseService.updateSet(id, data).then((res) => {
            if (res.status === 'success') {
                setUpdateEnrolled(false);
                fetchCourseDetails();
            } else toast.error(res.message);
        });
    };

    const handleModelClick = () => {
        if (action === 'add' && currData) {
            addSetToCourse(currData as CourseSetItem);
        } else if (action === 'remove' && currData) {
            removeSetFromCourse(currData as CourseSetItem);
        }
        setIsDefault(true)
        handleShow();
    };

    const handleAddClick = (set: SetItem) => {
        const order = addedSets.length + 1;
        if (!addedSets || addedSets.length == 0) {
            setCurrData({ ...set, isPrimary: true, order });
        } else {
            setCurrData({ ...set, order });
        }
        setAction('add');
        handleShow();
    };

    const handleRemoveClick = (set: CourseSetItem) => {
        setCurrData(set);
        setAction('remove');
        handleShow();
    };

    const handleDefaultSet = (_: any, v: boolean) => {
        setIsDefault(v);
        setUpdateEnrolled(false)
    }

    const handleDefaultClick = () => {
        alert("To change the behaviour you will have to delete it and add it again")
    }

    // Handle drag start
    const handleDragStart = (index: number) => {
        setDraggedItemIndex(index);
    };

    // Handle drag over
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    // Handle drop
    const handleDrop = async (index: number) => {
        if (draggedItemIndex === null || draggedItemIndex === index) return;
        const draggedItem = filteredAddedSets[draggedItemIndex];
        if (!draggedItem) return;
        const droppedItem = filteredAddedSets[index];
        if (!droppedItem) return;
        const hasBoth = addedSets?.some((set) => set?.id === draggedItem?.id) && addedSets?.some((set) => set?.id === droppedItem?.id);
        if (!hasBoth) return;

        try {
            const draggedPayload = { courseSetId: draggedItem?.id, order: (index + 1) };
            const droppedPayload = { courseSetId: droppedItem?.id, order: (draggedItemIndex + 1) }
            await CourseService.updateSet(draggedItem.id, draggedPayload);
            await CourseService.updateSet(droppedItem.id, droppedPayload);
            setAddedSets((prevState) => {
                const modifiedState = [...prevState];
                const temp = modifiedState[draggedItemIndex];
                modifiedState[draggedItemIndex].order = modifiedState[index].order;
                modifiedState[index].order = temp.order;
                const tempUpdated = modifiedState[draggedItemIndex];
                modifiedState[draggedItemIndex] = modifiedState[index];
                modifiedState[index] = tempUpdated
                return modifiedState;
            })
            toast.success("Order updated successfully")
        } catch (error) {
            toast.error(JSON.stringify(error))
        } finally {
            setDraggedItemIndex(null);
        }
    };

    const handleMouseDown = () => {
        if (!isDraggable) {
            setIsDraggable(true)
        }
    }

    const handleMouseLeave = () => {
        if (isDraggable) {
            setIsDraggable(false)
        }
    }

    return (
        <div className="row sets">
            <Modal show={show} onHide={handleShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Do you really want to {action} this set?</h5>
                    {action == 'add' && <CustomCheckbox
                        checked={isDefault}
                        onChecked={handleDefaultSet}
                        label={'Do you want to make this set default?'}
                    />}
                    {isDefault && <CustomCheckbox
                        checked={updateEnrolled}
                        onChecked={(_, v) => setUpdateEnrolled(v)}
                        label={
                            action == 'add'
                                ? 'Assign this set to existing students also?'
                                : 'Remove this set to existing students also?'
                        }
                    />}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleModelClick}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleShow}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-6">
                <h2>Available Sets</h2>
                <div className="my-4">
                    <input
                        type="text"
                        placeholder="Search sets"
                        className="form-control"
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="my-3 container row gap-3 mx-auto">
                    {filteredSets.map((set) => (
                        <div className="card col-12 col-sm-6 col-md-4 col-lg-3 px-0" key={set.id} style={{ minWidth: '170px' }}>
                            <img
                                className="w-100"
                                src={getMediaUrl(set.thumbnail)}
                                style={{ aspectRatio: '16/9' }}
                                alt={set.name}
                            />
                            <div className="card-body">
                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                    <Link to={`/contents/edit-set/${set.id}`} target="_blank">
                                        {set.name}
                                    </Link>
                                </p>
                            </div>
                            <div className="card-footer">
                                <a
                                    onClick={() => handleAddClick(set)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <IoMdAdd size={20} className="mx-auto" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-md-6" style={{ borderLeft: '1px solid #c3c3c3' }}>
                <h2>Added Sets</h2>
                <div className="my-4">
                    <input
                        type="text"
                        placeholder="Search sets"
                        className="form-control"
                        onChange={(e) => setAddedSetQuery(e.target.value)}
                    />
                </div>
                <div className="my-3 container row gap-3 mx-auto">
                    {filteredAddedSets.map((set, idx) => (
                        <div className="card col-12 col-sm-6 col-md-4 col-lg-3 px-0" key={set.setId}
                            draggable={isDraggable}
                            onDragStart={() => handleDragStart(idx)}
                            onDragOver={handleDragOver}
                            onDrop={() => handleDrop(idx)}
                            style={{ minWidth: '170px' }}
                        >
                            <img
                                className="w-100"
                                src={getMediaUrl(set.thumbnail) || ''}
                                style={{ aspectRatio: '16/9' }}
                                alt={set.name}
                            />
                            <div className="card-body">
                                <p className="card-title mb-0" style={{ fontSize: '14px' }}>
                                    <Link to={`/contents/edit-set/${set.setId}`} target="_blank">
                                        {set.name}
                                    </Link>
                                </p>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                                <a onClick={() => handleRemoveClick(set)}>
                                    <TriggerToolTip message='Remove set'>
                                        <span>
                                            <IoMdClose
                                                style={{ cursor: 'pointer' }}
                                                size={22}
                                                className="text-danger"
                                            />
                                        </span>
                                    </TriggerToolTip>
                                </a>
                                <div

                                    style={{ cursor: 'pointer' }}
                                    onClick={() => updateCourseSetStatus(set.id, !set.isPrimary)}
                                >
                                    {set.isPrimary ? (
                                        <TriggerToolTip message='Primary'>
                                            <span>
                                                <PiCrownFill size={19} className="text-primary" />
                                            </span>
                                        </TriggerToolTip>
                                    ) : (
                                        <TriggerToolTip message='Non primary'>
                                            <span>
                                                <PiCrownLight size={19} className="text-primary" />
                                            </span>
                                        </TriggerToolTip>
                                    )}
                                </div>
                                <>
                                    {set.isDefault ?
                                        <TriggerToolTip message='Default'>
                                            <span>
                                                <FiCheckSquare size={19} onClick={handleDefaultClick} />
                                            </span>
                                        </TriggerToolTip> :
                                        <TriggerToolTip message='Non default'>
                                            <span>
                                                <LuSquareDashed size={19} onClick={handleDefaultClick} />
                                            </span>
                                        </TriggerToolTip>
                                    }
                                </>
                                <span style={{ cursor: 'pointer' }}
                                    onMouseDown={handleMouseDown}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <RiDragMove2Line size={19} />
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SetTabs;
