import axios from 'axios';
import toast from 'react-hot-toast';

const eDeskClient = axios.create({
    baseURL:
        process.env.NODE_ENV == 'development'
            ? 'http://localhost:3000/v1'
            : 'https://edesk.esaral.com/v1',
    headers: {
        'Content-Type': 'application/json',
    },
});

eDeskClient.interceptors.request.use(
    (config) => {
        try {
            const user = localStorage.getItem('edeskToken');
            if (user) {
                config.headers.Authorization = `Bearer ${user}`;
            }
        } catch (error) {
            toast.error('contact to admin');
            localStorage.removeItem('edeskToken');
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { eDeskClient };
