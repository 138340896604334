import { Accordion, Button, Modal } from 'react-bootstrap';
import { FilteredQuestionData } from '../../../types/quizTypes';
import KatexRenderer from '../../Katex/KatexRenderer';

type Props = {
    showModal: boolean;
    handleHideModal: () => void;
    handleConfirm: () => void;
    selectedQuestionData: FilteredQuestionData;
    setSelectedQuestionData: React.Dispatch<React.SetStateAction<FilteredQuestionData>>;
};

const SimilarQuestionQuizModal: React.FC<Props> = ({
    showModal,
    selectedQuestionData,
    handleHideModal,
    handleConfirm,
    setSelectedQuestionData,
}) => {
    const handleAnswerChange = (selectedAnswer: any, event: any) => {
        setSelectedQuestionData((prev: any) => ({ ...prev, selectedQue: selectedAnswer }));
        event.stopPropagation();
    };

    const getSelectedSimilarQue = (ques: any) => {
        return selectedQuestionData?.selectedQue?.id === ques?.id;
    };

    return (
        <Modal show={showModal} onHide={handleHideModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Similar Questions Found</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {selectedQuestionData?.question?.qText && <KatexRenderer content={selectedQuestionData.question.qText} />}
                    <p className="text-secondary">
                        Pick one of the options below to ensure no duplicate questions are created
                    </p>
                    <Accordion>
                        {selectedQuestionData?.results?.map(
                            (result: { id: any; q_text: any, options: any[], qsolution: string }, index: number) => (
                                <Accordion.Item eventKey={result?.id} key={result?.id}>
                                    <Accordion.Header>
                                        <div className="d-flex align-items-baseline">
                                            <input
                                                type="radio"
                                                checked={getSelectedSimilarQue(result)}
                                                onChange={(e) => handleAnswerChange(result, e)}
                                                className="me-2"
                                                onClick={(e) => e.stopPropagation()}
                                            />

                                            <label onClick={(e) => handleAnswerChange(result, e)}>
                                                <KatexRenderer content={result.q_text} />
                                            </label>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {result?.options?.map((option: any, i: number) => (

                                            <div
                                                key={option.id}
                                                style={{ width: '100%', marginBottom: 5 }}
                                                className={`opt ${option.isSolution ? 'opt-correct' : 'opt-incorrect'
                                                    } d-flex align-items-baseline`}
                                            >
                                                {i === 0 ? '(A).' : i === 1 ? '(B).' : i === 2 ? '(C).' : '(D).'}
                                                <KatexRenderer
                                                    style={{ fontSize: '1rem', marginLeft: 15 }}
                                                    content={option.qOption || ''}
                                                />
                                            </div>

                                        ))}
                                        {result.qsolution && <>
                                            <hr />
                                            <KatexRenderer content={result.qsolution} />
                                        </>}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        )}
                    </Accordion>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleConfirm}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SimilarQuestionQuizModal;
