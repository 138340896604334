import { useEffect, useState } from 'react';
import { axiosClient } from '../services/axiosClient';
import generalService from '../services/ApiServices/general-service';
import targetService from '../services/ApiServices/target-service';
import studentClassService from '../services/ApiServices/student-class-service';
import courseService from '../services/ApiServices/course-service';
import { BACKEND_URLS } from '../config';
import toast from 'react-hot-toast';
import { LevelInterface } from '../types/ApiTypes';
import { TargetItem, ClassItem } from '../types/course.types';
import { NodeSettings } from '../types/node-types';

const useGetNodeFiltersData = (allowedFilters: {
    subject: boolean;
    class: boolean;
    exam: boolean;
    courses: boolean;
    settings: boolean;
}) => {
    const [subjectData, setSubjectData] = useState<LevelInterface[]>([]);
    const [targets, setTargets] = useState<TargetItem[]>([]);
    const [classes, setClasses] = useState<ClassItem[]>([]);
    const [settingsData, setSettingsData] = useState<NodeSettings | null>(null);
    const [phases, setPhases] = useState<string[]>([]);
    const [groups, setGroups] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (allowedFilters.subject) {
                    const subjectResponse = await axiosClient.get(BACKEND_URLS.LEVEL + 1);
                    setSubjectData(subjectResponse.data.data.level);
                }
                if (allowedFilters.exam) {
                    const targetResponse = await targetService.getAll();
                    setTargets(targetResponse.data.targets);
                }
                if (allowedFilters.class) {
                    const classResponse = await studentClassService.getAll();
                    setClasses(classResponse.data.classes);
                }
                if (allowedFilters.courses) {
                    const courseResponse = await courseService.getAllCourses();
                    const p = new Set<string>();
                    const g = new Set<string>();
                    courseResponse.data.courses.forEach((course) => {
                        const phase = course.phase;
                        const group = course.group;
                        if (phase) p.add(phase);
                        if (group) g.add(group);
                    });
                    setPhases([...p]);
                    setGroups([...g]);
                }
                if (allowedFilters.settings) {
                    const settingsResponse = await generalService.getSettings([
                        'categories',
                        'item_types',
                        'course_brandings',
                    ]);
                    setSettingsData(settingsResponse.data);
                }
            } catch (error: any) {
                toast.error(error.message);
            }
        };
        fetchData();
    }, []);

    return { subjectData, targets, classes, settingsData, phases, groups };
};

export default useGetNodeFiltersData;
