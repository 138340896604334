import { FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { ContentConstants } from '../../Redux/content/content-constants';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { LiveManagementConstants } from '../../Redux/live-mangment/live-mangment-constant';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { axiosClient } from '../../services/axiosClient';
import { AssetData } from '../../types/ApiTypes';
import { AssetType, ZoomSession } from '../../types/types';
import { convertISTtoUTC, convertUTCtoIST } from '../../utils/helpers';
import { SessionInterface, SessionType } from '../../types/LiveTypes';
import { sessionType } from '../../utils/constants/constants';

interface zoomUsersInterface {
    id: number;
    hostId: string;
    name: string;
    userEmail: string;
    accountId: string;
    clientId: string;
    clientSecret: string;
    webhookSecret: string;
}

type ZoomFields = 'auto' | 'manual';
const Sessions = ({ selectedTab }: { selectedTab?: string }) => {
    const { state, pathname }: { state: AssetData | undefined; pathname: string } = useLocation();
    const { contentId } = useParams();
    const sessions = useAppSelector((state) => state.liveMangment?.sessions) as SessionInterface[];
    const currentSessionId = useAppSelector((state) => state.liveMangment?.currentSessionId);
    const session = sessions.find((ses) => ses.id === currentSessionId);

    const [isLoading, setIsLoading] = useState(true); // New loading state

    const [faculties, setFaculties] = useState<any[]>([]);
    const [selectedFaculty, setSelectedFaculty] = useState<number | ''>(session?.facultyId || '');

    const [zoomUsers, setZoomUsers] = useState<zoomUsersInterface[]>([]);
    const [zoomUserHostId, setZoomUserHostId] = useState('');

    const [showZoomFields, setShowZoomFields] = useState<ZoomFields>('auto'); // show/hide Zoom fields based on selection
    const [platform, setPlatform] = useState(session?.sessionType || sessionType[0]);
    const [startDate, setStartDate] = useState(session?.startTime || '');
    const [endDate, setEndDate] = useState(session?.endTime || '');
    const [isRecording, setIsRecording] = useState(session?.isRecorded ?? true);
    const [sessionId, setSessionId] = useState('');
    const [internalName, setInternalName] = useState(session?.internalName || '');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { nodeId } = useParams();
    const [loading, setLoading] = useState(false);
    const [hasPDF, setHasPdf] = useState(session?.hasPDF ?? true);

    const selectedChapter = useAppSelector((state) => state?.ContentReducer?.selectedChapter) as {
        level2Id: number;
        level2Name: number;
    };

    const validateZoomFields = (data: any) => {
        setLoading(true);
        const requiredFields =
            showZoomFields === 'manual' ? ['sessionId'] : ['internalName', 'startTime', 'endTime'];
        if (showZoomFields !== 'manual' && platform === 'ZOOM') {
            requiredFields.push('hostId');
        }
        for (const field of requiredFields) {
            if (!data[field]) {
                toast.error(`Error: ${field} is required.`);
                return true;
            }
        }
        return false;
    };

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        // Check if endTime is greater than startTime
        if (new Date(endDate) <= new Date(startDate)) {
            toast.error('The end time cannot be earlier than the start time 🫡.');
            setLoading(false);
            return false; // Validation failed
        }
        if (currentSessionId) {
            try {
                const update = {
                    internalName,
                    sessionId: session?.sessionId || undefined,
                    password: session?.password || undefined,
                    startTime: startDate,
                    endTime: endDate,
                    recording: isRecording,
                    facultyId: selectedFaculty || undefined,
                    hasPDF,
                    // zoomUserEmail: "me", // Optional, if needed
                };

                const response = await axiosClient.put(
                    `${BACKEND_URLS.CREATE_SESSION}/${session?.id}`,
                    update
                );

                if (response.status >= 200 && response.status < 300) {
                    toast.success('Session updated successfully.');
                    const data = response.data.data;
                    dispatch({ type: ModalsConstants.IS_Modal, payload: false });
                    if (pathname.includes('live-mangment')) {
                        dispatch({
                            type: LiveManagementConstants.UPDATE_SESSION,
                            payload: response.data.data,
                        });
                    } else {
                        dispatch({
                            type: ContentConstants.EDIT_SESSION,
                            payload: { id: contentId, assetData: data },
                        });
                    }
                } else {
                    return toast.error(`Unexpected response:  ${response.data.message}`);
                }
            } catch (error: any) {
                if (error.response) {
                    setLoading(false);
                    toast.error(
                        `Failed to update session. Server responded with: ${
                            error.response.data.message || 'Unknown error'
                        }`
                    );
                    return;
                } else if (error.request) {
                    setLoading(false);
                    toast.error(
                        'No response from the server. Please check your network connection.'
                    );
                    return;
                } else {
                    setLoading(false);
                    toast.error(`Unexpected error: ${error.message}`);
                    return;
                }
            }
        } else {
            const data =
                showZoomFields == 'manual'
                    ? { sessionId }
                    : {
                          type: platform,
                          internalName: internalName,
                          startTime: startDate,
                          endTime: endDate,
                          recording: isRecording,
                          hasPDF,
                          hostId: zoomUserHostId || undefined,
                          facultyId: selectedFaculty || undefined,
                      };
            try {
                if (validateZoomFields(data)) {
                    setLoading(false);
                    return;
                }
                const sessionCreated = await axiosClient.post(BACKEND_URLS.CREATE_SESSION, data);
                const sessionCreatedData: ZoomSession = sessionCreated.data.data;

                const content = {
                    isNew: true,
                    id: -1,
                    name: sessionCreatedData.internalName,
                    assetType: AssetType.SESSION,
                    assetId: sessionCreatedData.id,
                    type: '',
                    level2Id: selectedChapter.level2Id,
                    level2Name: selectedChapter.level2Name,
                    level3Id: null,
                    parentAssetType: 'null',
                    parentAssetId: 0,
                    isFree: false,
                    createdOn: '',
                    assetData: sessionCreatedData,
                    hasPDF,
                };

                dispatch({ type: ContentConstants.ADD_CONTENT, payload: content });
                dispatch({ type: ModalsConstants.IS_Modal, payload: false });
                navigate(
                    FRONTEND_URLS.CONTENTS.EDIT_NODE +
                        `/${nodeId}` +
                        '/' +
                        FRONTEND_URLS.CONTENTS.ASSET +
                        '/' +
                        content.id
                );
            } catch (error: any) {
                setLoading(false);
                toast.error(error.response.data.message);
            }
            // finally {
            //     dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: null })
            // }
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosClient.get(BACKEND_URLS.ZOOM_USERS);
                const users = res.data.data;
                setZoomUsers(users);
                const user = users.find((zu: any) => zu.id == session?.zoomInstanceId);
                user && setZoomUserHostId(user.hostId);
                const facultyRes = await axiosClient.get(BACKEND_URLS.GET_USERS, {
                    params: { role: 'faculty' },
                });
                if (facultyRes.data.status == 'success') {
                    setFaculties(facultyRes.data.data.users);
                }

                // if (currentSessionId) {
                //     setInternalName(session?.internalName ?? '');
                //     setZoomUserId(session?.zoomInstanceId);
                //     setEndDate(session?.endTime ?? '');
                //     setStartDate(session?.startTime ?? '');
                //     setIsRecording(session?.isRecorded ?? true);
                //     setHasPdf(session?.hasPDF ?? true);
                // } else {
                //     setZoomUser(users[0]);
                // }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false); // Ensure loading state is updated
            }
        };

        fetchData();
    }, []);

    if (isLoading) return <div>Loading...</div>; // Render a loader until data is ready

    const isLive = selectedTab === 'live';

    return (
        <Form onSubmit={handleSubmit} className="p-2">
            <Form.Group controlId="platformSelect" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Platform</Form.Label>
                <div className="col-sm-9">
                    <Form.Select
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value as SessionType)}
                    >
                        {sessionType.map((type) => (
                            <option value={type}>{type}</option>
                        ))}
                    </Form.Select>
                </div>
            </Form.Group>

            {platform === 'ZOOM' && (
                <Form.Group controlId="zoomUserSelect" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Zoom User</Form.Label>
                    <div className="col-sm-9">
                        <Form.Select
                            value={showZoomFields}
                            onChange={(e) => setShowZoomFields(e.target.value as ZoomFields)}
                            disabled={!!state || isLive}
                        >
                            <option value="auto">Auto</option>
                            <option value="manual">Manual</option>
                        </Form.Select>
                    </div>
                </Form.Group>
            )}

            {showZoomFields == 'manual' ? (
                <Form.Group controlId="zoomId" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Session ID</Form.Label>
                    <div className="col-sm-9">
                        <Form.Control
                            type="text"
                            placeholder="123456"
                            value={sessionId}
                            onChange={(e) => setSessionId(e.target.value)}
                        />
                    </div>
                </Form.Group>
            ) : (
                <>
                    <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Session Name</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="text"
                                value={internalName}
                                onChange={(e) => setInternalName(e.target.value)}
                                placeholder="write internal name here..."
                            />
                        </div>
                    </Form.Group>

                    {platform === 'ZOOM' && (
                        <Form.Group
                            controlId="zoomUserSelect"
                            className="mb-3 row align-items-center"
                        >
                            <Form.Label className="col-sm-3 col-form-label">Users</Form.Label>
                            <div className="col-sm-9">
                                <Form.Select
                                    value={zoomUserHostId}
                                    onChange={(e) => setZoomUserHostId(e.target.value)}
                                    disabled={!!state || isLive}
                                >
                                    <option value="" disabled>
                                        Select a user
                                    </option>
                                    {zoomUsers.map((user) => (
                                        <option key={user.hostId} value={user.hostId}>
                                            {user.name} ({user.userEmail})
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Form.Group>
                    )}

                    <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Start Date Time</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={startDate ? convertUTCtoIST(startDate) : ''}
                                disabled={isLive}
                                onChange={(e) => {
                                    const date = convertISTtoUTC(e.target.value);
                                    setStartDate(date);
                                    if (!endDate || new Date(endDate) < new Date(date)) setEndDate(date);
                                }}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="endDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">End Date Time</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={endDate ? convertUTCtoIST(endDate) : ''}
                                disabled={isLive}
                                onChange={(e) => setEndDate(convertISTtoUTC(e.target.value))}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="faculty" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">faculty</Form.Label>
                        <div className="col-sm-9">
                            <Form.Select
                                value={selectedFaculty}
                                onChange={(e) => {
                                    setSelectedFaculty(+e.target.value);
                                }}
                                disabled={isLive}
                            >
                                <option disabled value="">
                                    Select faculty
                                </option>
                                {faculties.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="saveRecording" className="mb-3 row align-items-center">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                            <Form.Check
                                type="checkbox"
                                label="Save Recording"
                                onChange={(e) => setIsRecording(e.target.checked)}
                                checked={isRecording}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="savePDF" className="mb-3 row align-items-center">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                            <Form.Check
                                type="checkbox"
                                label="Has Pdf"
                                onChange={(e) => setHasPdf(e.target.checked)}
                                checked={hasPDF}
                            />
                        </div>
                    </Form.Group>
                </>
            )}
            <Button variant="primary" type="submit" disabled={loading}>
                {session ? 'Edit' : 'Add'}
            </Button>
        </Form>
    );
};

export default Sessions;
