import { CreatePayload } from '../../../types/course.types';
import { reciveDateTimeFormate } from '../../../utils/helpers';
import CustomUpload from '../../ModulePDF/Upload';
import CustomCheckbox from '../../common/custom-checkbox';
import { MEDIA_BASE_URL } from '../../../config';
import { useEffect, useState } from 'react';
import generalService from '../../../services/ApiServices/general-service';
import { getCourseSessions } from '../../../utils';
import { TERMS, TYPES } from '../../../utils/constants/dropdown-constants';
import TinyMceEditor from '../../../TinyMceEditor';
import UploadPromotionalVideo from '../upload-video';
import { Modal } from 'react-bootstrap';

const selectOptions = [
    {
        label: 'Draft',
        value: 'DRAFT',
    },
    {
        label: 'Published',
        value: 'PUBLISHED',
    },
];

type Props = {
    handleInputChange: (e: string, value: any) => void;
    isStatusDisabled?: boolean;
    data: CreatePayload;
};

const BasicDetailsTab = ({ handleInputChange, data, isStatusDisabled = true }: Props) => {
    const dscntPrcntg = (100 - (data?.discountedFee / data?.courseFee) * 100).toFixed(1) || 0;
    const [settings, setSettings] = useState<{
        course_types: string[];
        course_brandings: string[];
        course_terms: string[];
        course_target_exams: string[];
    }>({ course_types: [], course_brandings: [], course_terms: [], course_target_exams: [] });
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        generalService
            .getSettings([
                'course_types',
                'course_brandings',
                'course_terms',
                'course_target_exams',
            ])
            .then((res) => {
                if (res.status == 'success') setSettings(res.data);
            });
    }, []);

    const { start, end } = getCourseSessions();

    return (
        <div className="px-md-3 px-2">
            <div className="row">
                <div className="col-md-8">
                    <div className="row mt-3 gap-md-2 align-items-baseline">
                        <label className="form-label col-md-3 col-4" htmlFor="select-status">
                            Status
                        </label>
                        <div className="col-md-3 col-8">
                            <select
                                name="status"
                                className="form-select"
                                id="select-status"
                                disabled={isStatusDisabled}
                                defaultValue={data.status}
                                onChange={(e) => handleInputChange('status', e.target.value)}
                            >
                                {selectOptions.map((op) => (
                                    <option value={op.value} key={op.value}>
                                        {op.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row mt-4 gap-md-2 align-items-baseline">
                        <label className="form-label col-md-3 col-3">Name</label>
                        <div className="col-md-6 col-9">
                            <input
                                name="name"
                                value={data.name}
                                required
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                className="form-control"
                                placeholder="Course name"
                            />
                        </div>
                    </div>
                    <div className="col-12  my-2 row gap-md-3 align-items-baseline">
                        <label htmlFor="start-date" className="col-md-3 col-6">
                            Course Start Date
                        </label>
                        <div className="col-md-4 col-6 px-0 px-md-2">
                            <input
                                value={reciveDateTimeFormate(data.academicStartDate)[0]}
                                name="academicStartDate"
                                onChange={(e) =>
                                    handleInputChange('academicStartDate', e.target.value)
                                }
                                required
                                type="date"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 row gap-md-3 align-items-baseline">
                        <label htmlFor="start-date" className="col-md-3 col-6">
                            End Date
                        </label>
                        <div className="col-md-4 col-6 px-0 px-md-2">
                            <input
                                value={reciveDateTimeFormate(data.academicEndDate)[0]}
                                name="academicEndDate"
                                onChange={(e) =>
                                    handleInputChange('academicEndDate', e.target.value)
                                }
                                required
                                type="date"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-2 mb-3 mt-3 mt-md-0">
                    <CustomUpload
                        url={data?.thumbnail ? MEDIA_BASE_URL + data.thumbnail : ''}
                        onUploaded={(url) => handleInputChange('thumbnail', url)}
                    />
                </div>
            </div>
            <div className="row my-2 gap-2 align-items-baseline">
                <label className="form-label col-md-2">Description</label>
                <div className="col-md-8 col-12 text-dark">
                    <TinyMceEditor
                        height={250}
                        initialValue={data.description || ''}
                        onBlur={(_evt: any, editor: any) => {
                            handleInputChange('description', editor.getContent());
                        }}
                    />

                    {/* <SummerNote
                        initialValue={data.description || ''}
                        onBlur={(e) => handleInputChange('description', e.getContent())}
                        height={200}
                    /> */}
                </div>
            </div>
            <div className="row my-2 align-items-baseline gap-2">
                {/* Fees */}
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Fee
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            value={data.courseFee}
                            name="courseFee"
                            onChange={(e) => handleInputChange('courseFee', e.target.value)}
                            required
                            type="number"
                            placeholder="0.00"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3  align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Dicsounted Fee
                        {dscntPrcntg && !isNaN(+dscntPrcntg) && `(${dscntPrcntg})%`}
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            required
                            value={data.discountedFee}
                            name="discountedFee"
                            onChange={(e) => {
                                handleInputChange('discountedFee', e.target.value);
                            }}
                            type="number"
                            placeholder="0.00"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Min sale price
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            value={data.minSalePrice}
                            name="minSalePrice"
                            required
                            onChange={(e) => handleInputChange('minSalePrice', e.target.value)}
                            type="number"
                            placeholder="0.00"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Sales start date
                    </label>
                    <div className="col-md-4  col-6 px-0 px-md-2">
                        <input
                            value={reciveDateTimeFormate(data.salesStartDate)[0]}
                            name="salesStartDate"
                            required
                            onChange={(e) => handleInputChange('salesStartDate', e.target.value)}
                            type="date"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label className="col-md-4 col-6">
                        Visible to Sales Team
                    </label>
                    <div className="col-md-4  col-6 px-0 px-md-2">
                        <CustomCheckbox
                            checked={data.availableToSales}
                            onChecked={handleInputChange}
                            name="availableToSales"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label className="col-md-4 col-6">
                        Visible on Store
                    </label>
                    <div className="col-md-4  col-6 px-0 px-md-2">
                        <CustomCheckbox
                            checked={data.availableOnStore}
                            onChecked={handleInputChange}
                            name="availableOnStore"
                        />
                    </div>
                </div>
                {/* <div className="col-12 d-flex flex-md-row flex-column justify-content-start my-2">
                    <div className="col-md-6">
                        <CustomCheckbox
                            className="custom-check-1"
                            checked={data.availableToSales}
                            onChecked={handleInputChange}
                            name="availableToSales"
                            label="Visible to Sales Team"
                        />
                    </div>{' '}
                    <div className="col-md-6">
                        <CustomCheckbox
                            className="custom-check-2"
                            checked={data.availableOnStore}
                            onChecked={handleInputChange}
                            name="availableOnStore"
                            label=" Visible on Store"
                        />
                    </div>
                </div> */}
            </div>
            <div className="mt-3 row my-2 align-items-baseline gap-2">
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="start_session" className="col-md-4 col-6">
                        Session Start Year
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            id="start_session"
                            value={data?.sessionStartYear || ''}
                            onChange={(e) => handleInputChange('sessionStartYear', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {start.map((d) => (
                                <option value={d} key={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="end_session" className="col-md-4 col-6">
                        Session End Year
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            value={data.sessionEndYear || ''}
                            id="end_session"
                            onChange={(e) => handleInputChange('sessionEndYear', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {end.map((d) => (
                                <option value={d} key={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row my-2 align-items-baseline  gap-2">
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="branding" className="col-md-4 col-6">
                        Branding
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            id="branding"
                            value={data.branding || ''}
                            onChange={(e) => handleInputChange('branding', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {settings.course_brandings?.map((d) => (
                                <option value={d} key={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="type" className="col-md-4 col-6">
                        Type
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            value={data.type || ''}
                            id="type"
                            onChange={(e) => handleInputChange('type', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {settings.course_types?.map((d) => (
                                // @ts-ignore
                                <option value={d} key={d}>{TYPES[d]}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="term" className="col-md-4 col-6">
                        Term
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            value={data.term || ''}
                            id="term"
                            onChange={(e) => handleInputChange('term', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {settings.course_terms?.map((d) => (
                                // @ts-ignore
                                <option value={d} key={d}>{TERMS[d]}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="target-exams" className="col-md-4 col-6">
                        Target Exams
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <select
                            className="form-select"
                            id="target-exams"
                            value={data.targetExam || ''}
                            onChange={(e) => handleInputChange('targetExam', e.target.value)}
                        >
                            <option disabled value={''}>
                                --
                            </option>
                            {settings.course_target_exams?.map((d) => (
                                <option value={d} key={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Group
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            value={data.group}
                            name="group"
                            onChange={(e) => handleInputChange('group', e.target.value)}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3  align-items-baseline">
                    <label htmlFor="start-date" className="col-md-4 col-6">
                        Phase
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            required
                            value={data.phase}
                            name="phase"
                            onChange={(e) => {
                                handleInputChange('phase', e.target.value);
                            }}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label className="col-md-4 col-6">
                        Show with Tax
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <CustomCheckbox
                            checked={!!data.showWithTax}
                            onChecked={handleInputChange}
                            name="showWithTax"
                        />
                    </div>
                </div>
                {/* <div className="col-12 d-flex flex-md-row flex-column justify-content-start my-2">
                    <div className="col-md-6">
                        <CustomCheckbox
                            className="custom-check-2"
                            checked={!!data.showWithTax}
                            onChecked={handleInputChange}
                            name="showWithTax"
                            label="Show with Tax"
                        />
                    </div>
                </div> */}
                <div className="col-12 d-flex flex-md-row flex-column justify-content-start my-2">
                    <label htmlFor="promotional-video" className="col-5">
                        Promotional Video
                        <span className="text-primary">
                            {fileName || (data as any).promotionalVideo?.internalName || ''}
                        </span>
                    </label>
                    <button
                        onClick={() => setShowModal(true)}
                        className="btn btn-primary btn-sm px-3"
                    >
                        Upload
                    </button>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label className="col-md-4 col-6">
                        Enrollment Order
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            value={data.orderEnrollment}
                            name="orderEnrollment"
                            onChange={(e) => handleInputChange('orderEnrollment', e.target.value)}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12 row gap-md-3 align-items-baseline">
                    <label className="col-md-4 col-6">
                        Store Order
                    </label>
                    <div className="col-md-4 col-6 px-0 px-md-2">
                        <input
                            value={data.orderStore}
                            name="orderStore"
                            onChange={(e) => handleInputChange('orderStore', e.target.value)}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Promotional Video Upload'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadPromotionalVideo
                        onSuccess={(e) => {
                            setFileName(e.fileName);
                            handleInputChange('promotionalVideoId', e.id);
                            setShowModal(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BasicDetailsTab;
