import toast from 'react-hot-toast';
import { BACKEND_URLS, URLS } from '../config';
import { VideoSource } from '../types/types';
import { axiosClient } from './axiosClient';
import { getPosterUrls } from '../utils/helpers';

export default {
    getNodeChildren: async function (id: number) {
        try {
            const response = await axiosClient.get(URLS.NODE_CHILDREN + '/' + id);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    updateParentNodeId: async function (data: any) {
        try {
            const response = await axiosClient.patch(URLS.UPDATE_PARENT_ID, {
                ...data,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postNodeTreeCRUD: async function (formData: any) {
        try {
            const response = await axiosClient.post(URLS.NODE_CHILDREN_CRUD, {
                node_data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putNodeTreeCRUD: async function (formData: any) {
        try {
            const response = await axiosClient.put(URLS.NODE_CHILDREN_CRUD, {
                node_data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    attcchNodeTreeCRUD: async function (formData: any) {
        try {
            const response = await axiosClient.post(URLS.ATTACH_NODE_CHILDREN, {
                node_data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAllSubcourseNodes: async function () {
        try {
            const response = await axiosClient.get(URLS.ALL_SUBCOURSE_NODES);
            return response.data;
        } catch (error: any) {
            toast.error(error.message);
            return [];
        }
    },
    createNodeContent: async function ({
        node_id,
        node_type,
        node_name,
        content_type,
        content_group,
        name,
        video_id,
        original_file_url,
        encrypted_file_url,
        pdf_password,
        thumbnail = '',
        duration = 0,
        page_count = 0,
        size_in_kb = 0,
        resolution = '',
        is_free = false,
        is_shareable = false,
        vdo_cipher_id,
    }: any) {
        try {
            const data = {
                node_id,
                node_type,
                node_name,
                is_free,
                is_shareable,
                thumbnail,
                content_type,
                content_group,
                name,
                duration,
                page_count,
                size_in_kb,
                resolution,
                video_id,
                original_file_url,
                encrypted_file_url,
                pdf_password,
                vdo_cipher_id,
            };
            const response = await axiosClient.post(URLS.CRUD_NODE_CONTENT, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getVDOinfo: async function (video_id: any) {
        try {
            const response = await axiosClient.get(
                'https://dev.vdocipher.com/api/meta/' + video_id
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    fetchVideoDetails: async (
        videoID: string,
        source: VideoSource
    ): Promise<{
        source: VideoSource;
        fileName: string;
        durInSec: number;
        thumbnailUrlHr: string;
        thumbnailUrlLr: string;
    }> => {
        try {
            const response = await axiosClient.get(
                `${BACKEND_URLS.FETCH_VIDEO_DETAILS}/${videoID}/${source}`
            );
            if (response.data?.status === 'success') {
                const data = response.data.data;
                if (source === VideoSource.VIMEO) {
                    return {
                        ...data,
                        thumbnailUrlHr: data.thumbnailHr,
                        thumbnailUrlLr: data.thumbnailLr,
                    };
                } else {
                    const posters = getPosterUrls(data.posters);
                    return {
                        source,
                        fileName: data.title,
                        durInSec: data.length,
                        thumbnailUrlHr: posters.hrUrl,
                        thumbnailUrlLr: posters.lrUrl,
                    };
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error: any) {
            throw new Error(error.message || 'No video found for this video ID.');
        }
    },
    getVideoOTP: async function ({
        videoId,
        source = VideoSource.VDOCIPHER,
    }: {
        videoId: any;
        source: VideoSource;
    }) {
        const res = await axiosClient.post(BACKEND_URLS.VDOCIPHER_OTP, {
            source: source,
            videoId: videoId,
            // "otpDuration": 12345,
            download: false,
        });
        if (res.data.status === 'success') {
            return res.data.data;
        } else {
            throw res.data.errors.join(', ');
        }
    },
    getNodeInfo: async function (id = 0) {
        try {
            const response = await axiosClient.post(URLS.NODE_INFO, { node_id: id });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNodeContentInfo: async function (id: number) {
        try {
            const response = await axiosClient.post(URLS.NODE_CONTENT_INFO, {
                node_id: id,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getContentList: async function () {
        try {
            const response = await axiosClient.post(URLS.CONTENT_LIST);
            return response.data;
        } catch (error: any) {
            toast.error(error.message);
            return [error];
        }
    },
    uploadQuiz: async function (data: any) {
        try {
            const response = await axiosClient.post(URLS.UPLOAD_QUIZ, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getQuiz: async function (data: any) {
        try {
            const response = await axiosClient.post(URLS.GET_QUIZ, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    removeNodeLink: async function (data: any) {
        try {
            const response = await axiosClient.post(URLS.REMOVE_NODE_LINK, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    copyNodeLink: async function (node_link_id: number) {
        try {
            const response = await axiosClient.post(URLS.COPY_NODE_LINK, {
                node_link_id,
            });
            return response.data;
        } catch (error: any) {
            toast.error(error.message);
            throw error;
        }
    },

    getNodeTree: async function getNodeTree(node_id: number) {
        try {
            const res = await axiosClient.post(URLS.NODE_TREE, { node_id });
            const data = res.data;
            if (data.success) {
                return data.data.node_tree;
            }
            return [];
        } catch (error) {
            throw error;
        }
    },
    getAllSubjectChapters: async function (subcourse_id = 0) {
        return axiosClient.post(URLS.GET_ALL_SUBJECTS_CHAPTER, { subcourse_id }).then((res) => {
            if (res.data.success) {
                return res.data.data;
            } else {
                throw new Error(res.data.errors.join(', '));
            }
        });
    },
};
