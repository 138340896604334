import { useEffect } from "react";

const useDisableNumberInputControls = () => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.target instanceof HTMLInputElement && e.target.type === "number") {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                }
            }
        };

        const handleWheel = (e: WheelEvent) => {
            if (document.activeElement instanceof HTMLInputElement && document.activeElement.type === "number") {
                e.preventDefault();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
};

export default useDisableNumberInputControls;
