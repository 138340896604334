import renderWithKatex from "./helper";
import "katex/dist/katex.min.css";
interface KatexRendererProps extends React.HTMLAttributes<HTMLDivElement> {
  content?: string;
}

const KatexRenderer = ({ content, ...props }: KatexRendererProps) => {
  let parsedContent = renderWithKatex(content || "");
  return (
    <div
      className="text-start"
      dangerouslySetInnerHTML={{ __html: parsedContent }}
    ></div>
  );
};

export default KatexRenderer;
