import axios from 'axios';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { eDeskClient } from './edeskClient';

const EDeskLogin = ({ onSuccess }: { onSuccess: () => void }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSend, setIsOtpSend] = useState(false);

    const sendOtp = async () => {
        if (mobileNumber.length !== 10) {
            alert('Mobile number must be 10 digits');
            return;
        }
        try {
            const res = await eDeskClient.post('/auth/send-otp', {
                mobileNumber,
                countryCode: '+91',
                appVersion: '1.0.0',
            });
            const resp = res.data;
            if (resp.status === 'success') {
                setIsOtpSend(true);
            }
        } catch (error) {
            alert('Error sending OTP');
        }
    };

    const verifyOtp = async () => {
        if (otp.length !== 4) {
            alert('OTP must be 4 digits');
            return;
        }
        try {
            const res = await eDeskClient.post('/auth/verify-otp', {
                mobileNumber,
                countryCode: '+91',
                otp,
                deviceInfo: {
                    uniqueId: 'ABC-123',
                    os: 'Windows',
                    osVersion: 'chrome',
                    deviceName: 'Samsung',
                    buildId: 'ABC-123',
                    brand: 'esaral',
                    deviceId: 'abcif',
                    displayId: 'esaral',
                    hardwareId: 'esaral',
                    manufacturerName: 'esaral',
                    productName: 'esaral',
                },
            });
            const resp = res.data;
            if (resp.status === 'success') {
                localStorage.setItem('edeskToken', resp.data.token);
                onSuccess();
                alert('Login Successful');
            }
        } catch (error) {
            alert('Error verifying OTP');
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">EDesk Login</h2>
            <Form className="w-50 mx-auto">
                {!isOtpSend ? (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter 10-digit mobile number"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={sendOtp} className="w-100">
                            Send OTP
                        </Button>
                    </>
                ) : (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Enter OTP</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter 4-digit OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="success" onClick={verifyOtp} className="w-100">
                            Verify OTP
                        </Button>
                    </>
                )}
            </Form>
        </div>
    );
};

export default EDeskLogin;
