import { AssetType } from './types';

export type quizQuestionType =
    | 'SINGLE_CORRECT'
    | 'MULTIPLE_CORRECT'
    | 'NUMERICAL'
    | 'SUBJECTIVE'
    | 'MATCH_THE_COLUMN'
    | '';

export enum QuizType {
    QUIZ = 'QUIZ',
    PRACTICE_QUIZ = 'PRACTICE_QUIZ',
}
export type QuizPayload = {
    quizType: QuizType;
    internalName: string;
    showInstructions: boolean;
    timeLimit: number;
    minTimeSubmission: number;
    totalAttempts: number;
    showSolutions: boolean;
    showRank: boolean;
    showReport: boolean;
    postSubmissionMessage: string;
    language: string;
    sections: QuizSectionItem[];
    instructions?: string;
};

export interface QuizItemResponse {
    id: number;
    createdById: number | null;
    quizType: string;
    internalName: string;
    showInstructions: boolean;
    instructions: string;
    timeLimit: number;
    minTimeSubmission: number;
    totalAttempts: number;
    showSolutions: boolean;
    showRank: boolean;
    showReport: boolean;
    postSubmissionMessage: string;
    language: string;
    isDeleted: boolean;
    level1Id: number | null;
    level2Id: number | null;
    createdOn: string; // Can be changed to `Date` type if needed
    updatedOn: string | null; // Can be `Date | null`
}

export type QuestionOptionItem = {
    optionOrder: number;
    qOption: string;
    questionId: number;
    isSolution: boolean;
};

export type CreateOptionItem = {
    id?: number;
    qOption: string;
    optionOrder: number;
    isSolution: boolean;
    isNew?: boolean;
    isDeleted?: boolean;
};

export type videoSolutionType = {
    content: {
        id: number;
        assetId: number;
        assetType: AssetType.VIDEO;
        name: string;
    };
    editRequired: false;
    updatedOn: string;
    userId: number;
    status?: 'COMPLETED' | 'NOT_STARTED' | 'IN_PROGRESS';
};

export type QuizItem = QuizPayload & { id: number; questions: QuestionItem[] } & {
    createdOn: string;
    updatedOn: string;
    createdById: number;
};
export type QuestionItem = QuestionPayload & { id: number } & {
    videoSolutions: videoSolutionType[];
};

export type QuestionPayload = {
    questionId?: number;
    qOrder: number;
    qType: quizQuestionType;
    qTypeLabel: string;
    qInstructions?: string;
    language: string;
    sectionId: number | null;
    paragraphId: number | null;
    paragraph?: string;
    qText: string;
    qHint: string;
    qSolution: string;
    positiveMarks: number;
    negativeMarks: number;
    rangeFrom: number;
    rangeTo: number;
    correctAnswer: number;
    level1Id?: number;
    level2Id?: number;
    partialMarks: number;
    options: CreateOptionItem[];
    matches:
    | {
        option: string;
        matchOptionIndexes: number[];
    }[]
    | undefined;
};

export type ParagraphItem = {
    id: number;
    paragraph: string;
};

export type QuizSectionItem = {
    id?: number;
    sectionName: string;
    maxQuestions: number;
    isDeleted?: boolean;
    isNew?: boolean;
};

export type QuizAttemptItem = {
    id: number;
    quizId: number;
    userId: number;
    status: 'IN_PROGRESS' | 'COMPLETED' | 'ABENDED';
    startTime: string;
    endTime: string;
    correctAnswers: number;
    incorrectAnswers: number;
    skippedAnswers: number;
    marksScored: number;
    totalMarks: number;
    quizTotalMarks: number;
    timeTaken: number;
    quiz: { internalName: string };
};

export type StudentQuestionAnswer = {
    id: number;
    quizAttemptId: number;
    questionId: number;
    qOrder: number;
    answerOptionsId?: number;
    answerNumerical?: number;
    isCorrect: boolean;
    marksObtained: number;
    attemptState: string;
};

export type QuestionVideoSolutionItem = {
    id: number;
    questionId: number;
    videoUrl: string;
    editRequired: boolean;
    recordedById?: number;
};

export type AttachQuestion = {
    questionId: any,
    qOrder: number,
    sectionId: number,
    positiveMarks: number,
    negativeMarks: number,
    partialMarks: number
}

export type FilteredQuestionData = { question?: any; results: any[]; selectedQue: any };