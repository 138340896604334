import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS } from '../../config';
import { AxiosError, AxiosResponse } from 'axios';
import { LevelInterface } from '../../types/ApiTypes';
import { SelectCreator } from '../selector/SelectCreator';
import { useParams } from 'react-router-dom';
import { pdfUploadHelper } from '../../utils/helpers'
import toast from 'react-hot-toast';

type formTypes = {
  name: string;
  internalName: string;
  imageUrl: string;
  isSubject?: boolean;
  isChapter?: boolean;
  level1Id?: number | null;
  order?: number | undefined;
};

type levelModalInterface = {
  show: boolean;
  handleSubmit: (formData: formTypes) => void;
  handleClose: () => void;
  level: string;
  nodeData?: formTypes | null;
};

const LevelsModal = ({
  nodeData,
  show,
  handleSubmit,
  handleClose,
  level,
}: levelModalInterface) => {
  const [name, setName] = useState("");
  const [internalName, setInternalName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isSubject, setIsSubject] = useState(true);
  const [isChapter, setIsChapter] = useState(false); // Adjust based on your requirements
  const [subjects, setSubjects] = useState<LevelInterface[]>([]);
  const [order, setOrder] = useState<number | undefined>(1)
  const [imgLoading, setImgLoading] = useState(false)

  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(
    null
  );

  const createHandleSubmit = (e: any) => {
    e.preventDefault();
    const formData = {
      name,
      internalName,
      imageUrl,
      isSubject,
      isChapter,
      level1Id: selectedSubjectId,
      order
    };
    if (level == `${2}`) {
      if (selectedSubjectId == null) {
        toast.error("subject is required.");
        return;
      }
    }

    if (level == `${1}`) {
      if (!formData.imageUrl) {
        toast.error("Image is required.");
        return;
      }
      if (!formData.order) {
        toast.error("Order is required.");
        return;
      }
    }

    handleSubmit(formData);
    setName("");
    setInternalName("");
    setImageUrl("");
    setIsChapter(false);
    setIsSubject(false);
    handleClose();
    setSelectedSubjectId(null);
  };


  useEffect(
    () => {
      if (level == '2') {
        axiosClient.get(BACKEND_URLS.LEVEL + 1).then(
          (res: AxiosResponse) => {
            setSubjects(res.data.data.level)
          }
        ).catch(
          (err: AxiosError) => {
            toast.error(err.message)
          }
        )
      }
      if (nodeData) {
        setName(nodeData.name)
        setInternalName(nodeData.internalName)
        setImageUrl(nodeData.imageUrl)
        setIsChapter(nodeData.isChapter ?? false)
        setIsSubject(nodeData.isSubject ?? false)
        setSelectedSubjectId(nodeData.level1Id ?? 0)
        setOrder(nodeData.order)
      }
    }, [nodeData, level]
  )

  const handleCancel = () => {
    setName("");
    setInternalName("");
    setImageUrl("");
    setIsChapter(false);
    setIsSubject(false);
    handleClose();
    setSelectedSubjectId(null);
    handleClose();
  };

  const handleUploadIcon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setImgLoading(true)
      const file = (event.target as HTMLInputElement).files?.[0];
      const imgUrl = await pdfUploadHelper(file);
      setImageUrl(imgUrl);
    } catch (error) {
      toast.error(JSON.stringify(error))
    } finally {
      setImgLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleCancel} size='lg' id="levels">
      <Modal.Header closeButton>
        <Modal.Title>Enter Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={createHandleSubmit}>
          {/* Name Input */}
          <Form.Group as={Row} controlId="formName" className="align-items-center py-2">
            <Form.Label column sm={3}>
              Name
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                placeholder={`Enter ${level == '0' ? 'subject group' : level == '1' ? 'subject' : level == '2' ? 'chapter' : 'content group'} name`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Col>
          </Form.Group>

          {/* Internal Name Input */}
          <Form.Group as={Row} controlId="formInternalName" className="align-items-center py-2">
            <Form.Label column sm={3}>
              Internal Name
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                placeholder={`Enter internal name`}
                value={internalName}
                onChange={(e) => setInternalName(e.target.value)}
                required
              />
            </Col>
          </Form.Group>

          {/* File Upload */}
          <Form.Group as={Row} controlId="floatingInput" className="align-items-center py-2">
            <Form.Label column sm={3}>
              Upload Image
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="file"
                name="file"
                onChange={handleUploadIcon}
              />
            </Col>
          </Form.Group>

          {/* Conditional Fields */}
          {level === '2' && (
            <Form.Group as={Row} controlId="formSelect" className="align-items-center py-2">
              <Col sm={9}>
                <SelectCreator
                  labelName="Subject"
                  options={subjects.map((lev) => ({
                    optionName: lev.internalName,
                    optionValue: lev.id,
                  }))}
                  onChange={(event: any) => setSelectedSubjectId(Number(event.target.value))}
                  selectedId={selectedSubjectId}
                />
              </Col>
            </Form.Group>
          )}

          {level === '1' && (
            <Form.Group as={Row} controlId="formIsSubject" className="align-items-center mt-2 py-2 py-2">
              <Form.Label column sm={3}>
                Order
              </Form.Label>
              <Col>
                <Form.Control
                  value={order}
                  onChange={(e) => setOrder(Number(e.target.value))}
                />
              </Col>
            </Form.Group>
          )}
          {level === '1' && (
            <Form.Group as={Row} controlId="formIsSubject" className="align-items-center mt-2 py-2 py-2">
              <Form.Label column sm={3}>
                Is Subject
              </Form.Label>
              <Col>
                <Form.Check
                  type="checkbox"
                  checked={isSubject}
                  onChange={(e) => setIsSubject(e.target.checked)}
                />
              </Col>
            </Form.Group>
          )}


          {level === '2' && (
            <Form.Group as={Row} controlId="formIsChapter" className="align-items-center ">
              <Form.Label column sm={3}>
                Is Chapter
              </Form.Label>
              <Col>
                <Form.Check
                  type="checkbox"
                  checked={isChapter}
                  onChange={(e) => setIsChapter(e.target.checked)}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group >
            <Button variant="primary" type="submit" className='d-flex justify-content-end' disabled={imgLoading}>
              {nodeData ? 'Edit' : 'Submit'}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LevelsModal;
