import { number, string } from 'yup';
import { EnrollmentType } from './order';

export interface userProfileInterface {
    name?: string;
    email?: string;
    profileUrl?: string;
    dateOfBirth?: string;
    gender?: string;
    admissionFormProgress?: string;
    category?: string;
    currentCourseId?: string;
    currentSetId?: string;
    isPwd?: string;
}

export enum CONTACT_TYPE {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    WHATSAPP = 'WHATSAPP',
}

export enum RELATION_TYPE {
    SELF = 'SELF',
    MOTHER = 'MOTHER',
    UNCLE = 'UNCLE',
    FATHER = 'FATHER',
}
export enum GENDER {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum CATEGORY_TYPE {
    OPEN = 'OPEN',
    SC = 'SC',
    ST = 'ST',
    OBC_NCL = 'OBC_NCL',
}

export enum ADDRESS_TYPE {
    PERMANENT = 'PERMANENT',
    CURRENT = 'CURRENT',
}

export type UserContactPayload = {
    id?: number;
    userId?: number;
    type: CONTACT_TYPE;
    relation: RELATION_TYPE;
    value: string;
    name: string;
};

export type UserAddressPayload = {
    address: string;
    landmark: string | null;
    pincode: string;
    state: string;
    city: string;
    isPrimary: boolean;
    isActive: boolean;
    id?: number;
    type: ADDRESS_TYPE;
};

export type UserContactResponse = UserContactPayload & {
    id: number;
    userId: number;
};
export type UserAddressResponse = UserAddressPayload & {
    id: number;
    userId: number;
};

export type UserDetailsResponse = {
    id: number;
    name: string | null;
    username: string;
    profileUrl: string | null;
    gender: GENDER | null;
    dateOfBirth: string | null;
    createdOn: string | Date;
    updatedOn: string | Date;
    userAddress: UserAddressResponse[] | null;
    userProfile: UserProfileResponse | null;
    userAcademicInfo: UserAcademicInfoResponse[];
    userContacts: UserContactResponse[];
};

export type UserProfileResponse = {
    id: number;
    admissionFormProgress: number;
    category: CATEGORY_TYPE;
    isPwd: boolean;
    watchTimeXDays: number;
    watchTimeLifeTime: number;
    userId: number;
    primaryEmail: string;
    remark: string;
};

export type UserProfilePayload = {
    name: string | null;
    profileUrl: string | null;
    dateOfBirth: Date | string | null;
    gender: GENDER | null;
    admissionFormProgress?: number;
    category: CATEGORY_TYPE | null;
    isPwd: boolean;
};

export type UserAcademicInfoPayload = {
    session?: string;
    board?: string;
    schoolName?: string;
    schoolCity?: string;
    instituteName?: string;
    instituteCity?: string;
};
export type UserAcademicInfoResponse = {
    id: number;
    userId: number;
    session: string;
    studentClassId: number;
    board: string | null;
    schoolName: string | null;
    schoolCity: string | null;
    instituteName: string | null;
    instituteCity: string | null;
    targetId: number | null;
    targetExamYear: number;
};

export type User = {
    id: number;
    name: string | null;
    username: string;
    password: string;
    isStaff: boolean;
    utmSource: string | null;
    profileUrl: string | null;
    gender: string | null;
    dateOfBirth: string | null;
    createdById: number | null;
    createdOn: Date;
    updatedOn: Date | null;
    category: string | null;
    primaryEmail: string | null;
    isPwd: boolean;
    remark: string | null;
    userProfile: any;
};

export enum userTypesEnum {
    ALL = 'ALL',
    STAFF = 'STAFF',
    STUDENT = 'STUDENT',
}

export interface AssignedMentor {
    mentorId: number;
}

export interface Enrollment {
    assignedMentor: AssignedMentor[];
    courseId: number;
    id: number;
    orderDate: string;
    staffId: number;
    state: string;
    lastMentorCall: string | null;
    type: EnrollmentType;
    validTill: string;
    enrollmentTimetable: enrollmentTimetableInterface[];
}

export interface enrollmentTimetableInterface {
    content: {
        id: number;
        name: string;
        assetType: string;
        assetId: string;
        type: string;
        level2Id: number | null;
        level3Id: number | null;
        parentAssetType: string | null;
        parentAssetId: number | null;
        createdById: number | null;
        createdOn: string;
        updatedOn: string;
        isFree: boolean;
    };
}
