import { useState } from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { ProcessApiInterface, ProcessInterface } from '../../types/ApiTypes';
import { BACKEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import { useLoaderData } from 'react-router-dom';
import MainBtn from '../../components/btn/main-btn';
import ProcessModal from '../../components/modals/create-process-modal';
import toast from 'react-hot-toast';



export async function loader(): Promise<ProcessApiInterface> {
  const data = await axiosClient.get(BACKEND_URLS.PROCESSESS);
  return data.data;
}


function Processes() {
  const { data } = useLoaderData() as ProcessApiInterface;
  const [processData, setProcessData] = useState<ProcessInterface[]>(data.processes)
  const [show, setShow] = useState<boolean>(false)
  const [id, setId] = useState<number | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)

  const handleShow = () => setShow(true);

  const handleClick = (row: ProcessInterface) => {
    setId(row.id)
    setName(row.processName)
    setShow(true);
  };

  const servicesColumns = [
    {
      name: "id",
      selector: (row: ProcessInterface) => row.id,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.id} </div>
    },
    {
      name: "Process Name",
      selector: (row: ProcessInterface) => row.processName,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.processName} </div>
    },
    {
      name: "Role Id",
      selector: (row: ProcessInterface) => row.roleId,
      cell: (row: ProcessInterface) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.roleId} </div>
    },
    {
      name: "Created By",
      selector: (row: ProcessInterface) => row.createdBy?.name || '',
      cell: (row: ProcessInterface) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.createdBy?.name || ''} </div>
    },
    {
      name: "Created On",
      selector: (row: ProcessInterface) => row.createdOn?.split('T')[0],
      cell: (row: ProcessInterface) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.createdOn?.split('T')[0]} </div>
    },
    {
      name: "Updated On",
      selector: (row: ProcessInterface) => row.updatedOn?.split('T')[0],
      cell: (row: ProcessInterface) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.updatedOn?.split('T')[0]} </div>
    },
    {
      name: 'Action',
      cell: (row: any) => (
        <button className="btn btn-primary" onClick={() => handleClick(row)}>
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
    },
  ];

  const handleSubmit = async ({ name }: { name: string }) => {
    try {

      if (id) {
        const res = await axiosClient.put(BACKEND_URLS.PROCESSESS + `/` + id, {
          "processName": name,
        });

        if (res.status === 200) {
          toast.success("Process Item updated successfully!");
        } else {
          toast.error(`Failed to create Process. Status: ${res.status}`);
        }
      }
      else {
        const res = await axiosClient.post(BACKEND_URLS.PROCESSESS, {
          "processName": name,
          "roleId": 1
        });

        if (res.status === 201) {
          toast.success("Process created successfully!");
        } else {
          toast.error(`Failed to create Process. Status: ${res.status}`);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(`Error: ${error.response?.data?.message || "Failed to create Process."}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
    finally {
      setShow(false)
      setId(undefined)
      setName(undefined)
      const res = await axiosClient.get(BACKEND_URLS.PROCESSESS)
      setProcessData(res.data.data.processes)
    }
  };


  return (
    <div className='mx-4'>
      <h2>
        Processes
      </h2>

      <DataTable
        columns={servicesColumns}
        data={processData}  // Make sure to pass the correct data array here
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        customStyles={{
          table: {
            style: {
              width: '90%',
              margin: 'auto'
            }
          }
        }}
      />

      <ProcessModal handleSubmit={handleSubmit} setShow={setShow} show={show} key='Processes' id={id} serviceName={name} />

      <MainBtn
        message="create service"
        onClick={handleShow}
      />

    </div>
  )
}

export default Processes