import { useEffect, useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import DoubtTicketHeader from '../../../components/Doubts/DoubtTicketHeader';
import SingleDoubt from '../../../components/Doubts/SingleDoubt/SingleDoubt';
import DoubtService from '../../../services/ApiServices/doubt-service';
import userService from '../../../services/user-service';
import { TicketResponse } from '../../../types/DoubtTypes';
import { User } from '../../../types/user-profille-types';
import date from '../../../utils/helpers/date';
import toast from 'react-hot-toast';

const columns: TableColumn<TicketResponse>[] = [
    {
        name: 'Student Details',
        cell: (row) => {
            const maskedPhone = row.user.username.replace(/(\d{3})\d+(\d{3})/, '$1XXXX$2');
            return (
                <div className='text-breaker'>
                    <span>{row.user.name}</span>
                    <br />
                    <span>{maskedPhone}</span>
                    <br />
                    <span>{'EMAIL'}</span>
                </div>
            );
        },
        sortable: true,
    },
    {
        name: 'Set',
        sortable: true,
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                className='text-breaker'
            >
                {' '}
                {row.set}{' '}
            </div>
        ),
    },
    {
        name: 'Assigned To',
        sortable: true,
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                className='text-breaker'
            >
                {' '}
                {row.assignedTo}{' '}
            </div>
        ),
    },
    {
        name: 'Department',
        sortable: true,
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                className='text-breaker'
            >
                {' '}
                {row.ticketType}{' '}
            </div>
        ),
    },
    {
        name: 'Created on',
        cell: (row: any) => {
            const timeDate = date.timeStampToStr(row.createdOn, false);
            return (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                >
                    {' '}
                    {`${timeDate.date} ${timeDate.time}`}{' '}
                </div>
            );
        },
        sortable: true,
    },
    {
        name: 'Unread messages',
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                className='text-breaker'
            >
                {' '}
                {row.unread}{' '}
            </div>
        ),
    },
    {
        name: 'Comment',
        sortable: true,
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                className='text-breaker'
            >
                {' '}
                {row.questionText}{' '}
            </div>
        ),
    },
    {
        name: 'Status',
        sortable: true,
        cell: (row: any) => (
            <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            >
                {' '}
                {row.status}{' '}
            </div>
        ),
    },
];

function Doubts() {
    const [allTickets, setAllTickets] = useState<TicketResponse[]>([]);
    const [userId, setUserId] = useState<number>();
    const [show, setShow] = useState(false);
    const [selectedDoubt, setSelectedDoubt] = useState<TicketResponse | null>(null);
    const [filter, setFilter] = useState('');
    const [type, setType] = useState('ALL');
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilterApply = (value: any) => {
        setFilter(value);
    };

    const handleTypeChange = (e: any) => {
        setType(e);
    };

    const getDoubts = async () => {
        try {
            setIsLoading(true)
            const response = await DoubtService.getAllTickets();
            const tickets = response.data.tickets;
            const userId = response.data.userId;
            setAllTickets(tickets);
            setUserId(userId);
        } catch (error) {
            toast.error(JSON.stringify(error))
        } finally {
            setIsLoading(false)
        }
    };

    const filteredData = useMemo(() => {
        return allTickets.filter((doubt) => {
            const timeDate = date.timeStampToStr(doubt.createdOn, false);
            const createdOnStr = `${timeDate.date} ${timeDate.time}`;
            return (
                (type === 'ALL' || doubt.status === type) &&
                (filter
                    ? doubt.questionText.toLowerCase().includes(filter.toLowerCase()) ||
                    createdOnStr.toLowerCase().includes(filter.toLowerCase()) ||
                    doubt.user.username.toLowerCase().includes(filter.toLowerCase()) ||
                    doubt.user.name.toLowerCase().includes(filter.toLowerCase()) ||
                    doubt?.assignedTo?.toLowerCase().includes(filter.toLowerCase())
                    : true)
            );
        });
    }, [type, filter, allTickets]);

    const handleRowClick = (row: TicketResponse) => {
        setSelectedDoubt(row);
        setShow(true);
    };

    const headerTypeData = () => {
        let ALL = 0;
        let NEW = 0;
        let OPENED = 0;
        let CLOSED = 0;
        let SOLVED = 0;

        for (let d of allTickets) {
            if (d.status === 'NEW') {
                NEW += 1;
            } else if (d.status === 'CLOSED') {
                CLOSED += 1;
            } else if (d.status === 'SOLVED') {
                SOLVED += 1;
            } else if (d.status === 'OPENED') {
                OPENED += 1;
            }
            ALL += 1;
        }
        return [
            {
                type: 'ALL',
                count: ALL,
            },
            {
                type: 'NEW',
                count: NEW,
            },
            {
                type: 'OPENED',
                count: OPENED,
            },
            {
                type: 'CLOSED',
                count: CLOSED,
            },
            {
                type: 'SOLVED',
                count: SOLVED,
            },
        ];
    };

    useEffect(() => {
        getDoubts();
        userService.getAllUsers(`isStaff=true`).then((res) => {
            if (res.status === 'success') {
                setUsers(res.data.users);
            }
        });
    }, [show]);

    return (
        <div className="mx-3">
            <h2>Doubts</h2>
            <div className="d-flex justify-content-end mb-3">
                <DoubtTicketHeader
                    data={headerTypeData()}
                    onFilterApply={handleFilterApply}
                    onTypeChange={handleTypeChange}
                />
            </div>
            {isLoading ? <div className='d-flex justify-content-center'><Spinner /></div> :
                <DataTable
                    columns={columns}
                    data={filteredData}
                    onRowClicked={handleRowClick}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 500, 1000]}
                    highlightOnHover
                    pointerOnHover
                    customStyles={{
                        table: {
                            style: {
                                width: '90%',
                                margin: 'auto',
                            },
                        },
                    }}
                />}
            <Modal show={show} onHide={() => setShow(false)} keyboard={false} fullscreen={true}>
                {selectedDoubt && (
                    <SingleDoubt
                        userId={userId ?? 0}
                        users={users}
                        ticket={selectedDoubt}
                        onHide={() => setShow(false)}
                    />
                )}
            </Modal>
        </div>
    );
}

export default Doubts;
