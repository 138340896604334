import { useEffect, useState } from 'react';
import { NodeItem } from '../../types/node.types';
import NodeService from '../../services/ApiServices/node-service';
import { Form, Table } from 'react-bootstrap';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import SetService from '../../services/ApiServices/set-service';
import { SetNodeItem } from '../../types/setTypes';
import './styles.css';
import { Link } from 'react-router-dom';
import { FRONTEND_URLS } from '../../config';
import { fuzzyUnorderedSearch } from '../../utils/helpers';
import useGetNodeFiltersData from '../../hooks/useGetNodeFiltersData';

type Props = {
    setId: number;
    nodes: SetNodeItem[];
};

const SetNodes = ({ nodes, setId }: Props) => {
    const [allNodes, setAllNodes] = useState<NodeItem[]>([]);
    const [query, setQuery] = useState('');
    const [addedNodeQuery, setAddedNodeQuery] = useState('');
    const [setNodes, setSetNodes] = useState(nodes);
    const [ids, setIds] = useState(nodes.map((i) => i.nodeId));

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedContentType, setSelectedContentType] = useState('');

    const { subjectData, targets, classes, settingsData, } = useGetNodeFiltersData({
        subject: true,
        class: true,
        exam: true,
        courses: false,
        settings: true,
    });

    useEffect(() => {
        NodeService.getAll().then((res) => {
            const nodes = res.data.nodes;
            setAllNodes(nodes);
        });
    }, []);

    const addNodeToSet = (id: number) => {
        SetService.addNode(setId, { nodeId: id }).then((res) => {
            if (res.status === 'success') {
                getNodeDetailsById();
                setIds((prev) => [...prev, id]);
            }
        });
    };

    const removeNodeFromSet = (id: number) => {
        SetService.removeNode(setId, { nodeId: id }).then((res) => {
            if (res.status === 'success') {
                setIds((prev) => prev.filter((i) => i !== id));
                getNodeDetailsById();
            }
        });
    };

    const getNodeDetailsById = () => {
        SetService.getNodes(setId).then((res) => {
            if (res.status === 'success') {
                setSetNodes(res.data.nodes);
            }
        });
    };

    const filteredNodes = allNodes.filter((node) => {
        const normalizedQuery = query.toLowerCase().trim();
        const normalizedCategory = selectedCategory.toLowerCase().trim();
        const normalizedGrade = selectedGrade.toLowerCase().trim();
        const normalizedSubject = selectedSubject.toLowerCase().trim();
        const normalizedContentType = selectedContentType.toLowerCase().trim();

        // Collect all non-empty filters into an array of functions
        const filters: Array<(name: string) => boolean> = [
            normalizedCategory ? (name: string) => name.includes(normalizedCategory) : null,
            normalizedGrade ? (name: string) => name.includes(normalizedGrade) : null,
            normalizedSubject ? (name: string) => name.includes(normalizedSubject) : null,
            normalizedContentType ? (name: string) => name.includes(normalizedContentType) : null,
            normalizedQuery ? (name: string) => fuzzyUnorderedSearch(name, normalizedQuery) : null,
        ].filter(Boolean) as Array<(name: string) => boolean>; // Ensure type safety

        // If no filters are provided, return all nodes
        if (filters.length === 0) return true;

        // Skip nodes with excluded IDs
        if (ids.includes(node.id)) return false;

        const name = node.internalName.toLowerCase();

        // Check if the node matches all provided filters
        return filters.every((filter) => filter(name));
    });

    const filteredAddedNodes = setNodes.filter((node) =>
        fuzzyUnorderedSearch(node.internalName, addedNodeQuery)
    );

    return (
        <div className="row mx-auto">
            <div className="col-md-5 border border-1 px-0 rounded fixed-table">
                <h4 className="p-3 pb-0 text-center">Available Nodes</h4>
                <hr className="mb-0" />
                <div className="col-12 my-2 px-2 me-auto d-flex align-items-baseline fixed-input d-flex flex-column">
                    <div className="d-flex mb-2 ">
                        <Form.Select
                            className="mb-2 me-1 mb-md-0"
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                            }}
                            key="Exam"
                            value={selectedCategory}
                            style={{ flex: '1 1 160px' }}
                        >
                            <option value="">Exam</option>
                            {targets.map((category, index) => (
                                <option key={index + ' category'} value={category.name}>
                                    {category.name}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Select
                            className="mb-2 me-1 mb-md-0"
                            onChange={(e) => {
                                setSelectedGrade(e.target.value);
                            }}
                            key="grade"
                            value={selectedGrade}
                            style={{ flex: '1 1 160px' }}
                        >
                            <option value="">Class</option>
                            {classes.map((grade, index) => (
                                <option key={index + ' grade'} value={grade.name}>
                                    {grade.name}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Select
                            className="mb-2 me-1 mb-md-0"
                            onChange={(e) => {
                                setSelectedSubject(e.target.value);
                            }}
                            key="subject"
                            value={selectedSubject}
                            style={{ flex: '1 1 160px' }}
                        >
                            <option value="">Subject</option>
                            {subjectData.map((subject, index) => (
                                <option key={index + ' subject'} value={subject.name}>
                                    {subject.internalName}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Select
                            className="mb-2 mb-md-0"
                            onChange={(e) => {
                                setSelectedContentType(e.target.value);
                            }}
                            key="content-type"
                            value={selectedContentType}
                            style={{ flex: '1 1 160px' }}
                        >
                            <option value="">Content</option>
                            {settingsData?.item_types.map((content, index) => (
                                <option key={index + ' content-type'} value={content}>
                                    {content}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="d-flex w-100">
                        <input
                            placeholder="Type to search Nodes"
                            id="search"
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                            }}
                            className="form-control"
                        />
                    </div>
                </div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Node</th>
                            <th>Subject</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredNodes.map((node) => (
                            <tr key={node.id}>
                                <td>
                                    <Link
                                        to={FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${node.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {node.internalName}
                                    </Link>
                                </td>
                                <td>{node.level1Name}</td>
                                <td>
                                    <button
                                        className="text-primary btn btn-body"
                                        onClick={() => addNodeToSet(node.id)}
                                    >
                                        Add+
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="col-md-1 d-flex align-items-center flex-column fixed-column justify-content-center">
                <IoArrowBack size={28} />
                <IoArrowForward size={28} />
            </div>
            <div className="col-md-5 border border-1 px-0 rounded fixed-table">
                <h4 className="p-3 pb-0 text-center">Added Nodes</h4>
                <hr className="mb-0" />
                <div className="col-12 my-2 px-2 me-auto d-flex align-items-baseline fixed-input">
                    <input
                        placeholder="Type to search Nodes"
                        id="search"
                        value={addedNodeQuery}
                        onChange={(e) => setAddedNodeQuery(e.target.value)}
                        className="form-control"
                    />
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Node</th>
                            <th>Subject</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAddedNodes.map((node) => (
                            <tr key={node.id + node.internalName}>
                                <td>
                                    <Link
                                        to={FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${node.nodeId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {' '}
                                        {node.internalName}
                                    </Link>
                                </td>
                                <td>{node.level1Name}</td>
                                <td>
                                    <button
                                        className="text-danger btn btn-body"
                                        onClick={() => removeNodeFromSet(node.nodeId)}
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default SetNodes;