import { Badge, Card } from 'react-bootstrap';
import {
    hardwaresInterface,
    hardwearNameInterface,
    userEnrollmentsInterface,
    userEnrollmentsServices,
    userInterface,
} from '../../types/ApiTypes';
import { istToReadable } from '../../utils/helpers';
import { FaCheckCircle, FaUser, FaLaptop, FaTools } from 'react-icons/fa';

interface EnrollmentDetailsInterface {
    userEnrollments?: userEnrollmentsInterface[];
    enrollmentService?: userEnrollmentsServices[];
    hardwares?: (hardwaresInterface & hardwearNameInterface)[];
    salesPerson?: userInterface | null;
    courseId?: number | null;
}

function EnrollmentDetails({
    userEnrollments = [],
    enrollmentService = [],
    hardwares = [],
    salesPerson,
    courseId,
}: EnrollmentDetailsInterface) {
    return (
        <Card className='h-100' style={{ maxHeight: '675px', overflowY: 'auto' }}>
            <Card.Body className='justify-content-start'>
                <h4 className="mb-4">Enrollments Details</h4>
                <div className="row">
                    {userEnrollments.length > 0 && (
                        <div className="col-md-6 col-12 mb-3">
                            <strong className="d-flex align-items-center mb-2">
                                <FaCheckCircle className="me-2" /> Enrolled Courses
                            </strong>
                            <ul className="list-unstyled ms-4">
                                {userEnrollments?.map((enrollment) => (
                                    <li key={enrollment?.course?.id} className="mb-2" style={{ color: 'black' }}>
                                        {enrollment?.course?.name} |{' '}
                                        <strong>{istToReadable(enrollment?.validTill, true, false)}</strong>{' '}
                                        {enrollment?.course?.id === courseId && (
                                            <Badge bg="success" className="ms-2">current</Badge>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {enrollmentService.length > 0 && (
                        <div className="col-md-6 col-12 mb-3">
                            <strong className="d-flex align-items-center mb-2">
                                <FaTools className="me-2" /> Services Offered
                            </strong>
                            <ul className="list-unstyled ms-4">
                                {enrollmentService?.map((service) => (
                                    <li key={service?.id} className="mb-2" style={{ color: 'black' }}>
                                        {service?.service?.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {hardwares.length > 0 && (
                        <div className="col-md-6 col-12 mb-3">
                            <strong className="d-flex align-items-center mb-2">
                                <FaLaptop className="me-2" /> Hardwares
                            </strong>
                            <ul className="list-unstyled ms-4">
                                {hardwares?.map((hardware) => (
                                    <li key={hardware?.id} className="mb-2" style={{ color: 'black' }}>
                                        {hardware?.hardware?.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {Object.keys(salesPerson ?? {}).length > 0 && (
                        <div className="col-md-6 col-12">
                            <strong className="d-flex align-items-center mb-2">
                                <FaUser className="me-2" /> Sales Person:
                            </strong>
                            <span className='ms-4'>
                                {salesPerson?.name ?? ''} ({salesPerson?.username ?? ''})
                            </span>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export default EnrollmentDetails;
