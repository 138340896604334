import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks';
import { LiveManagementConstants } from '../../../Redux/live-mangment/live-mangment-constant';
import sessionService from '../../../services/ApiServices/session-service';
import {
    liveMangmentTabsType,
    nodeContentMappingInterface,
    SessionInterface,
} from '../../../types/LiveTypes';
import { istToReadable } from '../../../utils/helpers';
import zoomIcon from '../../../assets/images/icon-zoom.png'

const HandleNodeIds = ({ ids }: { ids: nodeContentMappingInterface[] }) => {
    return (
        <div
            data-tag="allowRowEvents"
            style={{
                overflow: 'hidden',
                whiteSpace: 'wrap',
                textOverflow: 'ellipses',
            }}
        >
            <ul>
                {ids?.map(
                    (node) =>
                        node.nodeId && (
                            <li key={node?.nodeId} style={{ color: 'black' }}>
                                <a
                                    className="me-1"
                                    href={`/edit-node/${node.nodeId}/content/${node?.contentId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={node.nodeInternalName}
                                >
                                    {node.nodeInternalName}
                                </a>
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};
interface LiveManagmentDatatableInterface {
    handleUpcomingSessionClick: (e: SessionInterface) => void;
    handleLaunch: (x: string, y: number) => void;
    handleViewClick: (e: SessionInterface) => void;
    handleRecordings: (e: SessionInterface) => void;
}

type TabData = Record<liveMangmentTabsType, SessionInterface[]>;

function LiveManagmentDatatable({
    handleUpcomingSessionClick,
    handleLaunch,
    handleViewClick,
    handleRecordings,
}: LiveManagmentDatatableInterface) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectedTab = location.pathname.split('/')[3] as liveMangmentTabsType;
    const [syncing, setSyncing] = useState(false);
    const [zoomMeetingIds, setZoomMeetingIds] = useState<Set<number>>(new Set());
    const sessions = useAppSelector((state) => state.liveMangment?.sessions) as SessionInterface[];
    const [isFilter, setIsFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const isModal = useAppSelector((state) => state?.ModalReducer?.isModal);

    const columns = (() => {
        switch (selectedTab) {
            case 'live':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div data-tag="allowRowEvents" className="text-breaker d-flex align-items-center gap-1">
                                {row?.sessionType === "ZOOM" && <img src={zoomIcon} alt='zoom' width='20px' />}
                                <span>{row.internalName}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => (
                            <HandleNodeIds ids={row?.nodeContentMapping} />
                        ),
                    },
                    {
                        name: 'Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => {
                            const start = new Date(row.startTime);
                            const end = new Date(row.endTime);

                            return (
                                <div data-tag="allowRowEvents" className="text-breaker">
                                    {start.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}{' '}
                                    {start.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                    -
                                    {end.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            );
                        },
                    },
                    {
                        name: 'Launch',
                        cell: (row: SessionInterface) =>
                            row.zoomLaunchUrl ? (
                                <Link
                                    to={`${row.zoomLaunchUrl}`}
                                    onClick={() => handleLaunch(row.zoomLaunchUrl, row.id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        variant="default"
                                        size="sm"
                                        className="d-flex align-items-center gap-2 "
                                    >
                                        <FaExternalLinkAlt className="w-4 h-4" />
                                        Launch
                                    </Button>
                                </Link>
                            ) : (
                                <></>
                            ),
                    },
                    {
                        name: 'View Attendance',
                        cell: (row: SessionInterface) => (
                            <div data-tag="allowRowEvents" className="text-breaker">
                                <Button
                                    onClick={() => {
                                        handleViewClick(row); // Call the first function
                                        // setCurentSessionClick(row); // Call the second function
                                    }}
                                >
                                    View
                                </Button>
                            </div>
                        ),
                    },
                ];
            case 'completed':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div data-tag="allowRowEvents" className="text-breaker d-flex align-items-center gap-1">
                                {row?.sessionType === "ZOOM" && <img src={zoomIcon} alt='zoom' width='20px' />}
                                <span>{row.internalName}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => (
                            <HandleNodeIds ids={row?.nodeContentMapping} />
                        ),
                    },
                    {
                        name: 'Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => {
                            const start = new Date(row.startTime);
                            const end = new Date(row.endTime);

                            return (
                                <div data-tag="allowRowEvents" className="text-breaker">
                                    {start.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}{' '}
                                    {start.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                    -
                                    {end.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            );
                        },
                    },
                    {
                        name: 'Student Count',
                        selector: (row: SessionInterface) => row.studentCount,
                        sortable: true,
                    },
                    {
                        name: 'Upload video/pdf',
                        cell: (row: SessionInterface) => (
                            <Button onClick={() => handleRecordings(row)}>Replace</Button>
                        ),
                    },
                ];
            case 'in-progress':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div data-tag="allowRowEvents" className="text-breaker d-flex align-items-center gap-1">
                                {row?.sessionType === "ZOOM" && <img src={zoomIcon} alt='zoom' width='20px' />}
                                <span>{row.internalName}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => (
                            <HandleNodeIds ids={row?.nodeContentMapping} />
                        ),
                    },
                    {
                        name: 'Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => {
                            const start = new Date(row.startTime);
                            const end = new Date(row.endTime);

                            return (
                                <div data-tag="allowRowEvents" className="text-breaker">
                                    {start.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}{' '}
                                    {start.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                    -
                                    {end.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            );
                        },
                    },

                    {
                        name: 'Student Count',
                        selector: (row: SessionInterface) => row.studentCount,
                        sortable: true,
                    },
                    {
                        name: 'Upload video/pdf',
                        cell: (row: SessionInterface) => (
                            <Button onClick={() => handleRecordings(row)}>Upload</Button>
                        ),
                    },
                ];

            case 'cancelled':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div data-tag="allowRowEvents" className="text-breaker d-flex align-items-center gap-1">
                                {row?.sessionType === "ZOOM" && <img src={zoomIcon} alt='zoom' width='20px' />}
                                <span>{row.internalName}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => (
                            <HandleNodeIds ids={row?.nodeContentMapping} />
                        ),
                    },
                    {
                        name: 'Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => {
                            const start = new Date(row.startTime);
                            const end = new Date(row.endTime);

                            return (
                                <div data-tag="allowRowEvents" className="text-breaker">
                                    {start.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}{' '}
                                    {start.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                    -
                                    {end.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            );
                        },
                    },
                ];

            case 'schdeuled':
            default:
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div data-tag="allowRowEvents" className="text-breaker d-flex align-items-center gap-1">
                                {row?.sessionType === "ZOOM" && <img src={zoomIcon} alt='zoom' width='20px' />}
                                <span>{row.internalName}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: any) => <HandleNodeIds ids={row?.nodeContentMapping} />,
                    },

                    {
                        name: 'Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => {
                            const start = new Date(row.startTime);
                            const end = new Date(row.endTime);

                            return (
                                <div data-tag="allowRowEvents" className="text-breaker">
                                    {start.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}{' '}
                                    {start.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                    -
                                    {end.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            );
                        },
                    },
                    {
                        name: 'Launch',
                        cell: (row: SessionInterface) =>
                            row.zoomLaunchUrl ? (
                                <Link
                                    to={`${row.zoomLaunchUrl}`}
                                    onClick={() => handleLaunch(row.zoomLaunchUrl, row.id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        variant="default"
                                        size="sm"
                                        className="d-flex align-items-center gap-2 "
                                    >
                                        <FaExternalLinkAlt className="w-4 h-4" />
                                        Launch
                                    </Button>
                                </Link>
                            ) : (
                                <></>
                            ),
                    },
                ];
        }
    })();

    const filterAndSortSessions = (data: SessionInterface[]): TabData => {
        const now = new Date();

        const filters: TabData = {
            live: [],
            completed: [],
            'in-progress': [],
            cancelled: [],
            schdeuled: [],
        };

        const hasContent = (session: SessionInterface) => {
            const { isRecorded, hasPDF, videoId, pdfId } = session;

            if (isRecorded) {
                return !!videoId;
            }

            if (hasPDF) {
                return !!pdfId;
            }

            return false;
        };

        const isCompleted = (session: SessionInterface) => {
            return session.status === 'COMPLETED' && hasContent(session);
        };

        const isInProgress = (session: SessionInterface) => {
            const isPastEndTime = new Date(session.endTime) < now;
            const { isRecorded, hasPDF, videoId, pdfId, status } = session;

            if (!isPastEndTime || status === 'SCHEDULED') return false;

            // Check if content is missing based on flags
            if (isRecorded && !videoId) {
                return true;
            }

            if (hasPDF && !pdfId) {
                return true;
            }

            return false;
        };

        for (const session of data) {
            if (session.status === 'LIVE') {
                filters.live.push(session);
            } else if (session.status === 'SCHEDULED') {
                filters.schdeuled.push(session);
            } else if (session.status === 'COMPLETED') {
                filters.completed.push(session);
            } else if (session.status === 'IN_PROGRESS') {
                filters['in-progress'].push(session);
            } else {
                filters.cancelled.push(session);
            }
        }

        return filters;
    };

    const fiiltered = filterAndSortSessions(sessions);

    useEffect(() => {
        setLoading(true);
        const getSession = async () => {
            try {
                let query = '';
                if (!!from || !!to) {
                    const fromTimestamp = new Date(from).getTime() / 1000; // Convert to UNIX timestamp
                    const toTimestamp = new Date(to).getTime() / 1000;
                    const usageFromDate = new Date(fromTimestamp * 1000).toISOString();
                    const usageToDate = new Date(toTimestamp * 1000).toISOString();
                    query = `?from=${usageFromDate}&to=${usageToDate}`;
                }

                const sessionsResponse = await sessionService.getSessions(query);
                const meta = sessionsResponse.meta;

                if (meta) {
                    // Set default values for "From" and "To" fields
                    setFrom(new Date(meta.from).toISOString().slice(0, 16));
                    setTo(new Date(meta.to).toISOString().slice(0, 16));
                }
                dispatch({
                    type: LiveManagementConstants.SESSIONS,
                    payload: sessionsResponse.data,
                });

                setLoading(false);
            } catch (err) {
                console.error('Error fetching sessions:', err);
                setLoading(false);
            }

            try {
                setSyncing(true);

                const zoomResponse = await sessionService.getSessionsFromZoom();

                if (zoomResponse.status === 'success') {
                    let ids = zoomResponse.data.map((i) => i.id);
                    setZoomMeetingIds(new Set(ids));
                }
            } catch (err) {
                console.error('Error fetching Zoom sessions:', err);
            } finally {
                setSyncing(false);
            }
        };

        getSession();

        const interval = setInterval(() => {
            getSession();
        }, 1000 * 30);

        return () => {
            clearInterval(interval);
        };
    }, [isFilter]);

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            const fromTimestamp = new Date(from).getTime() / 1000; // Convert to UNIX timestamp
            const toTimestamp = new Date(to).getTime() / 1000;

            const usageFromDate = new Date(fromTimestamp * 1000).toISOString();
            const usageToDate = new Date(toTimestamp * 1000).toISOString();

            const query = `?from=${usageFromDate}&to=${usageToDate}`;

            const sessionsResponse = await sessionService.getSessions(query);
            const fiiltered = filterAndSortSessions(sessionsResponse.data);
            setLoading(false);
            setIsFilter((prev) => !prev);
        } catch (err) {
            console.error('Error during search:', err);
            setLoading(false);
        }
    };

    return (
        <>
            <Row>
                <Form onSubmit={handleSearch} className="d-flex flex-wrap gap-3">
                    <Col xs={12} md={6} lg={2}>
                        <FloatingLabel controlId="From" label="From">
                            <Form.Control
                                type="datetime-local"
                                value={from}
                                onChange={(e) => setFrom(e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>

                    <Col xs={12} md={6} lg={2}>
                        <FloatingLabel controlId="To" label="To">
                            <Form.Control
                                type="datetime-local"
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="pt-3">
                        <Button type="submit">Search</Button>
                    </Col>

                    <Col xs={12} md={6} lg={4} className="d-flex  justify-content-end">
                        {syncing ? (
                            <div className="d-flex align-items-center">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                Syncing...
                            </div>
                        ) : null}
                    </Col>
                </Form>
            </Row>
            <DataTable
                columns={columns}
                data={fiiltered[selectedTab]}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                onRowClicked={(row) => {
                    if (selectedTab === 'schdeuled' || selectedTab === 'live') {
                        handleUpcomingSessionClick(row);
                    }
                }}
                conditionalRowStyles={[
                    {
                        when: (row) =>
                            zoomMeetingIds.size > 0 && row?.sessionType !== "ZOOM" &&
                            !zoomMeetingIds.has(+row.sessionId) &&
                            (row.status == 'SCHEDULED' || row.status == 'LIVE'),
                        style: {
                            backgroundColor: '#FFCCCB',
                        },
                    },
                ]}
                customStyles={{
                    table: {
                        style: {},
                    },
                }}
                progressPending={loading}
            />
        </>
    );
}

export default LiveManagmentDatatable;
