import {
    CreatePayload,
    SingleCoursePayload,
    CreateCourseSetItem,
    CreateCourseProcessItem,
    CreateCourseHardwareItem,
    CreateCourseServiceItem,
    CreateCourseClassTargetItem,
    CourseType,
    CourseClassSessionItem,
} from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class CourseService extends ApiService {
    constructor() {
        super('/courses');
    }

    getAllCourses(): ApiResponse<{ courses: CourseType[] }> {
        return this.getData('/');
    }

    createCourse(data: CreatePayload) {
        return this.postData('/', data);
    }

    getCourseById(id: number): ApiResponse<{ course: SingleCoursePayload }> {
        return this.getData(`/${id}`);
    }

    updateCourse(id: number, data: Partial<CreatePayload>) {
        return this.putData(`/${id}`, data);
    }

    // Set
    addSet(id: number, data: CreateCourseSetItem) {
        return this.postData(`/${id}/sets`, data);
    }
    updateSet(id: number, data: { courseSetId: number }) {
        return this.putData(`/${id}/sets`, data);
    }
    removeSet(id: number, data: { setId: number }) {
        return this.deleteData(`/${id}/sets`, data);
    }
    getSets(params: string, courseId: number) {
        return this.getData(`/${courseId}/sets${params}`);
    }

    // Process
    addProcess(id: number, data: CreateCourseProcessItem) {
        return this.postData(`/${id}/processes`, data);
    }
    removeProcess(id: number, data: { processId: number }) {
        return this.deleteData(`/${id}/processes`, data);
    }

    // Class target
    addClassTarget(id: number, data: CreateCourseClassTargetItem) {
        return this.postData(`/${id}/class-targets`, data);
    }
    removeClassTarget(id: number, data: { courseClassTargetId: number }) {
        return this.deleteData(`/${id}/class-targets`, data);
    }

    // Services
    addServices(id: number, data: CreateCourseServiceItem) {
        return this.postData(`/${id}/services`, data);
    }
    removeServices(id: number, data: { serviceId: number }) {
        return this.deleteData(`/${id}/services`, data);
    }

    // Hardware
    addHardware(id: number, data: CreateCourseHardwareItem) {
        return this.postData(`/${id}/hardwares`, data);
    }

    removeHardware(id: number, data: { hardwareId: number }) {
        return this.deleteData(`/${id}/hardwares`, data);
    }

    addClassSession(id: number, data: CourseClassSessionItem[]) {
        return this.postData(`/${id}/class-sessions`, data);
    }

    updateClassSession(id: number, sessionId: number, data: Partial<CourseClassSessionItem>) {
        return this.putData(`/${id}/class-sessions/${sessionId}`, data);
    }
}
export default new CourseService();
