import { Button, Modal, Form, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { eDeskClient } from './edeskClient';

export type User = {
    profileImg: {
        id: bigint | undefined;
        url: string | null;
    };
    id: number;
    name: string | null;
    username: string;
    status: string | null;
    isActive: boolean;
    lastSeen: Date | null;
    contact: string;
};

const Users = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [show, setShow] = useState(false);
    const [editingUserId, setEditingUserId] = useState<number | null>(null);

    const [formData, setFormData] = useState({
        name: '',
        username: '',
        contact: '91',
        password: '',
    });

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            const res = await eDeskClient.get('/users');
            const resp = res.data;
            if (resp.status === 'success') {
                setUsers(resp.data);
            }
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const resetForm = () => {
        setFormData({
            name: '',
            username: '',
            contact: '91',
            password: '',
        });
        setEditingUserId(null);
    };

    const handleOpenAddUser = () => {
        resetForm();
        setShow(true);
    };

    const handleEditUser = (user: User) => {
        setEditingUserId(user.id);
        setFormData({
            name: user.name || '',
            username: user.username,
            contact: user.contact,
            password: '',
        });
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        resetForm();
    };

    const handleSaveUser = async () => {
        try {
            if (editingUserId) {
                const data = {
                    ...(formData.contact && { contact: formData.contact }),
                    ...(formData.username && { username: formData.username }),
                    ...(formData.password && { password: formData.password }),

                    ...(formData.name && { name: formData.name }),
                };
                const res = await eDeskClient.put(`/admin/users/${editingUserId}`, data);
                const resp = res.data;

                if (resp.status === 'success') {
                    toast.success('User updated successfully');

                    setUsers((prev) =>
                        prev.map((u) => (u.id === editingUserId ? { ...u, ...resp.data } : u))
                    );
                    handleClose();
                }
            } else {
                const res = await eDeskClient.post('/admin/users', formData);
                const resp = res.data;
                if (resp.status === 'success') {
                    setUsers([...users, resp.data]);
                    toast.success('New user added successfully');
                    handleClose();
                }
            }
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    return (
        <div className="p-4">
            <h4>Users</h4>
            <Button variant="primary" className="mb-3 btn-sm" onClick={handleOpenAddUser}>
                Add User
            </Button>

            <Table striped bordered hover className="mt-3 rounded">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Contact</th>
                        <th>Status</th>
                        <th style={{ width: '120px' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name || 'N/A'}</td>
                            <td>{user.username}</td>
                            <td>{user.contact}</td>
                            <td>{user.isActive ? 'Active' : 'Inactive'}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    size="sm"
                                    className="me-2"
                                    onClick={() => handleEditUser(user)}
                                >
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add/Edit Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingUserId ? 'Edit User' : 'Add User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="email"
                                value={formData.username}
                                onChange={(e) =>
                                    setFormData({ ...formData, username: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contact</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="91"
                                value={formData.contact}
                                onChange={(e) =>
                                    setFormData({ ...formData, contact: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {editingUserId ? 'Change Password' : 'Password'}
                            </Form.Label>
                            <Form.Control
                                type="password"
                                value={formData.password}
                                onChange={(e) =>
                                    setFormData({ ...formData, password: e.target.value })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveUser}>
                        {editingUserId ? 'Update User' : 'Add User'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Users;
