import "katex/dist/katex.min.css";
import katex from "katex";

const decodeHtmlEntities = (str: string) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

function renderWithKatex(content: string) {
  const regexInlineMath = /\$(.*?)\$/g;
  const regexBlockMath = /\$\$(.*?)\$\$/g;

  const htmlString = decodeHtmlEntities(content);

  let processedHtml = htmlString.replace(regexBlockMath, (_, p1) => {
    const renderedMath = katex.renderToString(p1, {
      displayMode: true,
      throwOnError: false,
    });
    return renderedMath;
  });

  processedHtml = processedHtml
    .replaceAll("<br>", "")
    .replace(regexInlineMath, (_, p1) => {
      const renderedMath = katex.renderToString(p1, {
        displayMode: false,
        throwOnError: false,
      });
      return renderedMath;
    });

  return processedHtml;
}

export default renderWithKatex;
