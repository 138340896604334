import { Tab, Tabs } from 'react-bootstrap';
import Users from './users';
import Presets from './presets';
import EDeskLogin from './edesk-login';
import { useEffect, useState } from 'react';

const EDesk = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('edeskToken');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogOut = () => {
        localStorage.removeItem('edeskToken');
        setIsLoggedIn(false);
    };

    return (
        <div className="container mt-4">
            {isLoggedIn ? (
                <>
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-4 text-center">EDesk Management System</h2>
                        <div>
                            <button className="btn btn-sm btn-danger" onClick={handleLogOut}>
                                LogOut
                            </button>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="users" id="edesk-tabs" className="mb-3">
                        <Tab eventKey="users" title="Users">
                            <Users />
                        </Tab>
                        <Tab eventKey="presets" title="Task Presets">
                            <Presets />
                        </Tab>
                    </Tabs>
                </>
            ) : (
                <EDeskLogin onSuccess={() => setIsLoggedIn(true)} />
            )}
        </div>
    );
};

export default EDesk;
