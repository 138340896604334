import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import courseService from "../../services/ApiServices/course-service";
import toast from "react-hot-toast";
import { EnrolledStudent } from "../../types/enrolled-student";
import enrollments from "../../services/enrollments";

interface AssignSetModalProps {
    show: boolean
    onHide: () => void
    selectedEnrollmentIds: number[]
    filterdData: EnrolledStudent[]
    clearEnrollmentIds: () => void
}

interface SetItem {
    setId: number
    isPrimary: boolean
    set: {
        name: string
    }
}

const AssignSetModal: React.FC<AssignSetModalProps> = ({ show, onHide, selectedEnrollmentIds, filterdData, clearEnrollmentIds }) => {
    const [setList, setSetList] = useState<SetItem[]>([]);
    const [selectedSet, setSelectedSet] = useState<SetItem | null>(null)

    const handleConfirm = async () => {
        const confirmSelection = window.confirm('Are you sure you want to assign the set?');
        if (confirmSelection) {
            try {
                if (selectedSet?.setId) {
                    const payload = {
                        setId: selectedSet.setId,
                        enrollmentIds: selectedEnrollmentIds
                    }
                    const response = await enrollments.assignSet(payload)
                    if (response.status === 'success') {
                        onHide();
                        clearEnrollmentIds()
                        toast.success(response.message || "Set assigned successfully")
                    }
                } else {
                    toast.error("Please select a set")
                }
            } catch (error) {
                toast.error(JSON.stringify(error))
            }
        }
    }

    const handleSelectSet = (event: React.ChangeEvent<HTMLInputElement>, selectedSet: SetItem) => {
        setSelectedSet(selectedSet)
    }

    const isSetSelected = (setId: number) => {
        return selectedSet?.setId === setId
    }

    const fetchSets = async () => {
        try {
            const courseId = filterdData?.find((enrollment) => enrollment.enrollment_id === selectedEnrollmentIds[0])?.course_id
            if (courseId) {
                const response = await courseService.getSets(`?isPrimary=false&isDefault=false`, courseId)
                if (response.status === 'success') {
                    setSetList(response.data)
                }
            }
        } catch (error) {
            toast.error(JSON.stringify(error))
        }
    }

    const handleClose = () => {
        clearEnrollmentIds();
        onHide();
    }

    useEffect(() => {
        fetchSets();
    }, []);

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>Assign Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {setList.length > 0 ? setList.map((set) => (
                    <div key={set.setId}>
                        <Form.Check
                            type="radio"
                            label={set?.set?.name || ""}
                            checked={isSetSelected(set.setId)}
                            onChange={(e) => handleSelectSet(e, set)}
                        />
                    </div>
                )) : <p>Did not found any additional sets for this course</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" disabled={setList.length === 0} onClick={handleConfirm}>Assign</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AssignSetModal