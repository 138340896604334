import React, { useState } from 'react';
import { Button, Form, Table, Card } from 'react-bootstrap';
import Select from 'react-select';
import { CONTACT_MODE_TYPE, REALATIONS } from '../../utils/constants';
import {
    CONTACT_TYPE,
    RELATION_TYPE,
    UserContactPayload,
    UserContactResponse,
} from '../../types/user-profille-types';
import { IoPencil, IoTrash } from 'react-icons/io5';
import toast from 'react-hot-toast';
import userService from '../../services/user-service';

type Props = {
    userContacts: UserContactResponse[];
    userId: number;
    isDisabled: boolean;
};

const ContactDetailsInfo = ({ userContacts, userId, isDisabled }: Props) => {
    const [contacts, setContacts] = useState<UserContactPayload[]>(userContacts);
    const [newContact, setNewContact] = useState<UserContactPayload>({
        name: '',
        relation: RELATION_TYPE.SELF,
        type: CONTACT_TYPE.PHONE,
        value: '',
    });
    const [editableRow, setEditableRow] = useState<number | null>(null);
    const [editContact, setEditContact] = useState<UserContactPayload | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (editableRow !== null) {
            setEditContact((prev) => prev && { ...prev, [name]: value });
        } else {
            setNewContact((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleRelationChange = (selectedOption: any) => {
        if (editableRow !== null) {
            setEditContact((prev) => prev && { ...prev, relation: selectedOption.value });
        } else {
            setNewContact((prev) => ({ ...prev, relation: selectedOption.value }));
        }
    };

    const handleContactTypeChange = (selectedOption: any) => {
        if (editableRow !== null) {
            setEditContact((prev) => prev && { ...prev, type: selectedOption.value });
        } else {
            setNewContact((prev) => ({ ...prev, type: selectedOption.value }));
        }
    };

    const addContact = () => {
        if (newContact.name && newContact.relation && newContact.type && newContact.value) {
            const payload = {
                ...newContact,
                name: newContact.name.trim(),
                value: newContact.value.trim(),
            };

            userService.addContact(userId, payload).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.message);
                    setContacts((prev) => [...prev, res.data.contact]);
                    setNewContact({
                        name: '',
                        relation: RELATION_TYPE.SELF,
                        type: CONTACT_TYPE.PHONE,
                        value: '',
                    });
                } else {
                    toast.error(res.message);
                }
            });
        } else {
            toast.error('Please fill in all fields before adding a contact.');
        }
    };

    const deleteContact = (id: number) => {
        if (window.confirm('You want to delete this contact?')) {
            userService.deleteContact(userId, id).then((res) => {
                if (res.status === 'success') {
                    setContacts((prev) => prev.filter((_, i) => _.id !== id));
                } else {
                    toast.error(res.message);
                }
            });
        }
    };

    const handleEditClick = (id: number) => {
        setEditableRow(id);
        const contact = contacts.find((itm) => itm.id === id)!;
        setEditContact(contact);
    };

    const updateContact = () => {
        if (editContact) {
            if (!editContact.id) {
                return;
            }

            const editPayload = {
                ...editContact,
                name: editContact?.name?.trim(),
                value: editContact?.value?.trim(),
            };

            userService.updateContact(userId, editContact.id!, editPayload).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.message);
                    const updatedContacts = contacts.map((contact) =>
                        contact.id === editableRow ? res.data.contact : contact
                    );
                    setContacts(updatedContacts);
                    setEditableRow(null);
                    setEditContact(null);
                } else {
                    toast.error(res.message);
                }
            });
        }
    };

    return (
        <Card className="my-4" style={{ overflow: 'hidden' }}>
            <Card.Header as="h5">Contact Details</Card.Header>
            <Card.Body>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Relation</th>
                            <th>Contact Type</th>
                            <th>Contact</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts?.map((contact, index) => (
                            <tr key={index}>
                                {editableRow === contact.id ? (
                                    <>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={editContact?.name || ''}
                                                onChange={handleInputChange}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                isDisabled={isDisabled}
                                                value={REALATIONS.find(
                                                    (r) => r.value === editContact?.relation
                                                )}
                                                options={REALATIONS}
                                                onChange={handleRelationChange}
                                                placeholder="Select Relation"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                menuPlacement="auto"
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                isDisabled={isDisabled}
                                                value={CONTACT_MODE_TYPE.find(
                                                    (c) => c.value === editContact?.type
                                                )}
                                                options={CONTACT_MODE_TYPE}
                                                onChange={handleContactTypeChange}
                                                placeholder="Select Contact Type"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                menuPlacement="auto"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                disabled={isDisabled}
                                                type="text"
                                                name="value"
                                                value={editContact?.value || ''}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            {!isDisabled && (
                                                <Button variant="success" onClick={updateContact}>
                                                    OK
                                                </Button>
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{contact.name}</td>
                                        <td>{contact.relation}</td>
                                        <td>{contact.type}</td>
                                        <td>{contact.value}</td>
                                        <td>
                                            {!isDisabled && (
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => handleEditClick(contact.id!)}
                                                    >
                                                        <IoPencil />
                                                    </Button>
                                                    <Button
                                                        className="mx-2"
                                                        variant="danger"
                                                        onClick={() => {
                                                            deleteContact(contact.id!);
                                                        }}
                                                    >
                                                        <IoTrash />
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={newContact.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter Name"
                                    disabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Select
                                    options={REALATIONS}
                                    onChange={handleRelationChange}
                                    placeholder="Select Relation"
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    menuPlacement="auto"
                                    isDisabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Select
                                    options={CONTACT_MODE_TYPE}
                                    onChange={handleContactTypeChange}
                                    placeholder="Select Contact Type"
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    menuPlacement="auto"
                                    isDisabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    name="value"
                                    value={newContact.value}
                                    onChange={handleInputChange}
                                    placeholder="Enter Contact"
                                    disabled={isDisabled}
                                />
                            </td>
                            <td>
                                {!isDisabled && (
                                    <Button onClick={addContact} variant="primary">
                                        Add
                                    </Button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default ContactDetailsInfo;
