import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { eDeskClient } from './edeskClient';
import { User } from './users';

type TaskPreset = {
    taskPresetJoinees: {
        id: number;
        taskPresetId: number;
        userId: number;
        isAdmin: boolean;
    }[];
    subTaskPreset: {
        id: number;
        taskPresetId: number;
        key: string;
    }[];
} & {
    id: number;
    presetName: string;
    requiresSubmit: boolean;
};

const Presets = () => {
    const [presets, setPresets] = useState<TaskPreset[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const [showPresetModal, setShowPresetModal] = useState(false);
    const [showJoineeModal, setShowJoineeModal] = useState<number | null>(null);
    const [showSubtaskModal, setShowSubtaskModal] = useState<number | null>(null);

    const [newPresetName, setNewPresetName] = useState('');
    const [newPresetRequiresSubmit, setNewPresetRequiresSubmit] = useState(false);

    const [subTaskKeys, setSubTaskKeys] = useState<string[]>([]);
    const [newSubtaskKey, setNewSubtaskKey] = useState('');

    const [existingSubTasks, setExistingSubTasks] = useState<{ id: number; key: string }[]>([]);

    const [currentJoinees, setCurrentJoinees] = useState<{ userId: number; isAdmin: boolean }[]>(
        []
    );

    const fetchData = async () => {
        try {
            const presetRes = await eDeskClient.get('/admin/task-preset');
            const presetResp = presetRes.data;
            if (presetResp.status === 'success') {
                setPresets(presetResp.data);
            } else {
                toast.error(presetResp.message);
            }

            const usersRes = await eDeskClient.get('/users');
            const usersResp = usersRes.data;
            if (usersResp.status === 'success') {
                setUsers(usersResp.data);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const addNewPreset = async () => {
        try {
            const res = await eDeskClient.post('/admin/task-preset', {
                presetName: newPresetName,
                requiresSubmit: newPresetRequiresSubmit,
            });
            const resp = res.data;
            if (resp.status === 'success') {
                setPresets((prev) => [...prev, resp.data]);
                toast.success('New preset added successfully');
                setShowPresetModal(false);
                setNewPresetName('');
                setNewPresetRequiresSubmit(false);
            } else {
                toast.error(resp.message);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    const openJoineeModal = (presetId: number) => {
        setShowJoineeModal(presetId);
        const preset = presets.find((p) => p.id === presetId);
        if (preset) {
            const existingJoinees = preset.taskPresetJoinees.map((j) => ({
                userId: j.userId,
                isAdmin: j.isAdmin,
            }));
            setCurrentJoinees(existingJoinees);
        }
    };

    const handleCheckUser = async (presetId: number, user: User) => {
        const isAlreadyJoinee = currentJoinees.some((j) => j.userId === user.id);
        if (!isAlreadyJoinee) {
            try {
                const payload = [{ userId: user.id, isAdmin: false }];
                const res = await eDeskClient.post(
                    `/admin/task-preset/${presetId}/joinees`,
                    payload
                );
                const resp = res.data;
                if (resp.status === 'success') {
                    toast.success(`${user.name} added successfully`);
                    setCurrentJoinees((prev) => [...prev, { userId: user.id, isAdmin: false }]);
                    setPresets((prevPresets) =>
                        prevPresets.map((p) => {
                            if (p.id !== presetId) return p;
                            return {
                                ...p,
                                taskPresetJoinees: [
                                    ...p.taskPresetJoinees,
                                    {
                                        id: resp.data?.[0]?.id || Math.random(),
                                        taskPresetId: presetId,
                                        userId: user.id,
                                        isAdmin: false,
                                    },
                                ],
                            };
                        })
                    );
                } else {
                    toast.error(resp.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.message);
            }
        } else {
            try {
                const res = await eDeskClient.delete(
                    `/admin/task-preset/${presetId}/joinees/${user.id}`
                );
                const resp = res.data;
                if (resp.status === 'success') {
                    toast.success(`${user.name} removed successfully`);
                    setCurrentJoinees((prev) => prev.filter((j) => j.userId !== user.id));
                    setPresets((prevPresets) =>
                        prevPresets.map((p) => {
                            if (p.id !== presetId) return p;
                            return {
                                ...p,
                                taskPresetJoinees: p.taskPresetJoinees.filter(
                                    (j) => j.userId !== user.id
                                ),
                            };
                        })
                    );
                } else {
                    toast.error(resp.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.message);
            }
        }
    };

    const handleToggleAdmin = async (presetId: number | null, userId: number, isAdmin: boolean) => {
        if (!presetId) return;
        try {
            const res = await eDeskClient.put(`/admin/task-preset/${presetId}/joinees/${userId}`, {
                isAdmin: !isAdmin,
            });
            const resp = res.data;
            if (resp.status === 'success') {
                toast.success('Admin toggled successfully');
                setCurrentJoinees((prev) =>
                    prev.map((joinee) =>
                        joinee.userId === userId ? { ...joinee, isAdmin: !isAdmin } : joinee
                    )
                );
                setPresets((prevPresets) =>
                    prevPresets.map((p) => {
                        if (p.id !== presetId) return p;
                        return {
                            ...p,
                            taskPresetJoinees: p.taskPresetJoinees.map((j) => {
                                if (j.userId === userId) {
                                    return { ...j, isAdmin: !isAdmin };
                                }
                                return j;
                            }),
                        };
                    })
                );
            } else {
                toast.error(resp.message);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    const openSubtaskModal = (presetId: number) => {
        setShowSubtaskModal(presetId);
        setSubTaskKeys([]);
        setNewSubtaskKey('');

        const preset = presets.find((p) => p.id === presetId);
        if (preset) {
            setExistingSubTasks(preset.subTaskPreset.map((st) => ({ id: st.id, key: st.key })));
        } else {
            setExistingSubTasks([]);
        }
    };

    const handleAddSubTaskKey = () => {
        const trimmedKey = newSubtaskKey.trim();
        if (trimmedKey && !subTaskKeys.includes(trimmedKey)) {
            setSubTaskKeys((prevKeys) => [...prevKeys, trimmedKey]);
        }
        setNewSubtaskKey('');
    };

    const handleRemoveSubTaskKey = (key: string) => {
        setSubTaskKeys((prevKeys) => prevKeys.filter((k) => k !== key));
    };

    const handleRemoveExistingSubTask = async (subtaskId: number) => {
        if (showSubtaskModal == null) return;
        try {
            const res = await eDeskClient.delete(`/task-preset/subtasks/${subtaskId}`);
            const resp = res.data;
            if (resp.status === 'success') {
                toast.success('Subtask preset removed successfully');

                setExistingSubTasks((prev) => prev.filter((st) => st.id !== subtaskId));

                setPresets((prevPresets) =>
                    prevPresets.map((p) => {
                        if (p.id !== showSubtaskModal) return p;
                        return {
                            ...p,
                            subTaskPreset: p.subTaskPreset.filter((st) => st.id !== subtaskId),
                        };
                    })
                );
            } else {
                toast.error(resp.message);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    const addSubTaskPresets = async () => {
        if (showSubtaskModal == null) return;
        if (!subTaskKeys.length) {
            setShowSubtaskModal(null);
            return;
        }
        try {
            const res = await eDeskClient.post(
                `/task-preset/${showSubtaskModal}/subtasks`,
                subTaskKeys
            );
            const resp = res.data;
            if (resp.status === 'success') {
                toast.success('Subtask presets added successfully');

                setPresets((prevPresets) =>
                    prevPresets.map((p) => {
                        if (p.id !== showSubtaskModal) return p;
                        const newSubtasks = subTaskKeys.map((key) => ({
                            id: Math.random(),
                            taskPresetId: p.id,
                            key,
                        }));
                        return {
                            ...p,
                            subTaskPreset: [...p.subTaskPreset, ...newSubtasks],
                        };
                    })
                );
            } else {
                toast.error(resp.message);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        } finally {
            setShowSubtaskModal(null);
            setSubTaskKeys([]);
            setNewSubtaskKey('');
        }
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
                <h1>Task Presets</h1>
                <button className="btn btn-primary" onClick={() => setShowPresetModal(true)}>
                    Add New Preset
                </button>
            </div>

            <table className="table table-striped mt-4">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Preset Name</th>
                        <th>Requires Submit</th>
                        <th>Joinees</th>
                        <th>Subtasks</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {presets.map((preset) => (
                        <tr key={preset.id}>
                            <td>{preset.id}</td>
                            <td>{preset.presetName}</td>
                            <td>
                                <input type="checkbox" checked={preset.requiresSubmit} readOnly />
                            </td>
                            <td>
                                {preset.taskPresetJoinees.slice(0, 2).map((joinee) => {
                                    const joineeUser = users.find((u) => u.id === joinee.userId);
                                    if (!joineeUser) return null;
                                    return (
                                        <div key={joinee.id}>
                                            {joinee.isAdmin
                                                ? `Admin: ${joineeUser.name}`
                                                : joineeUser.name}
                                        </div>
                                    );
                                })}
                                {preset.taskPresetJoinees.length > 2 && (
                                    <small className="text-muted">
                                        + {preset.taskPresetJoinees.length - 2} more
                                    </small>
                                )}
                            </td>
                            <td>
                                {/* Same truncation approach for subtasks */}
                                {preset.subTaskPreset.slice(0, 2).map((subtask) => (
                                    <div key={subtask.id}>{subtask.key}</div>
                                ))}
                                {preset.subTaskPreset.length > 2 && (
                                    <small className="text-muted">
                                        + {preset.subTaskPreset.length - 2} more
                                    </small>
                                )}
                            </td>
                            <td>
                                <button
                                    className="btn btn-success btn-sm me-2"
                                    onClick={() => openJoineeModal(preset.id)}
                                >
                                    Add Joinees
                                </button>
                                <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => openSubtaskModal(preset.id)}
                                >
                                    Add SubtaskPreset
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showPresetModal && (
                <div className="modal-overlay" onClick={() => setShowPresetModal(false)}>
                    <div
                        className="modal show d-block"
                        tabIndex={-1}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add New Preset</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowPresetModal(false)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        placeholder="Preset Name"
                                        value={newPresetName}
                                        onChange={(e) => setNewPresetName(e.target.value)}
                                    />
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={newPresetRequiresSubmit}
                                            onChange={(e) =>
                                                setNewPresetRequiresSubmit(e.target.checked)
                                            }
                                        />
                                        <label className="form-check-label">Requires Submit</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={addNewPreset}>
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setShowPresetModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* ─────────────────────────────────────────────────────────
          Joinees Modal
      ───────────────────────────────────────────────────────── */}
            {showJoineeModal && (
                <div className="modal-overlay" onClick={() => setShowJoineeModal(null)}>
                    <div
                        className="modal show d-block"
                        tabIndex={-1}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Manage Joinees</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowJoineeModal(null)}
                                    />
                                </div>
                                <div className="modal-body">
                                    {users.map((user) => {
                                        const isJoinee = currentJoinees.some(
                                            (j) => j.userId === user.id
                                        );
                                        const currentUserData = currentJoinees.find(
                                            (j) => j.userId === user.id
                                        );

                                        return (
                                            <div
                                                key={user.id}
                                                className="mb-2 d-flex align-items-center"
                                            >
                                                {/* Checkbox for user presence */}
                                                <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    checked={isJoinee}
                                                    onChange={() =>
                                                        handleCheckUser(showJoineeModal!, user)
                                                    }
                                                />
                                                <label className="form-check-label me-3">
                                                    {user.name}
                                                </label>

                                                {/* Admin toggle if user is a joinee */}
                                                {isJoinee && currentUserData && (
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={currentUserData.isAdmin}
                                                            onChange={() =>
                                                                handleToggleAdmin(
                                                                    showJoineeModal,
                                                                    user.id,
                                                                    currentUserData.isAdmin
                                                                )
                                                            }
                                                        />
                                                        <label className="form-check-label ms-1">
                                                            {currentUserData.isAdmin
                                                                ? 'Admin'
                                                                : 'User'}
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setShowJoineeModal(null)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* ─────────────────────────────────────────────────────────
          Subtask Modal with Tag Input + Removal
      ───────────────────────────────────────────────────────── */}
            {showSubtaskModal && (
                <div className="modal-overlay" onClick={() => setShowSubtaskModal(null)}>
                    <div
                        className="modal show d-block"
                        tabIndex={-1}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add SubtaskPreset</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowSubtaskModal(null)}
                                    />
                                </div>
                                <div className="modal-body">
                                    {/* Existing subtasks from DB */}
                                    {existingSubTasks.length > 0 && (
                                        <div className="mb-3">
                                            <h6>Existing Subtasks</h6>
                                            <div className="d-flex flex-wrap gap-2">
                                                {existingSubTasks.map((st) => (
                                                    <span key={st.id} className="badge bg-info">
                                                        {st.key}{' '}
                                                        <button
                                                            type="button"
                                                            className="btn-close btn-close-white ms-1"
                                                            aria-label="Remove"
                                                            onClick={() =>
                                                                handleRemoveExistingSubTask(st.id)
                                                            }
                                                            style={{ fontSize: '0.7rem' }}
                                                        />
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {/* Input for new subtasks */}
                                    <h6>Add New Subtasks</h6>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Subtask"
                                            value={newSubtaskKey}
                                            onChange={(e) => setNewSubtaskKey(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={handleAddSubTaskKey}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <div className="d-flex flex-wrap gap-2">
                                        {subTaskKeys.map((key, index) => (
                                            <span key={index} className="badge bg-primary">
                                                {key}{' '}
                                                <button
                                                    type="button"
                                                    className="btn-close btn-close-white ms-2"
                                                    aria-label="Close"
                                                    onClick={() => handleRemoveSubTaskKey(key)}
                                                    style={{ fontSize: '0.7rem' }}
                                                />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={addSubTaskPresets}>
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setShowSubtaskModal(null)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Presets;
