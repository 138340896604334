import { TERMS, TYPES } from '../utils/constants/dropdown-constants';
import { CourseSetItem } from './setTypes';

export type CourseType = CreatePayload & {
    id: number;
    createdAt: string;
    courseClassTargets: CourseClassTargetitem[];
};

export enum COURSE_STATUS {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export type SingleCoursePayload = CourseType & {
    sets: CourseSetItem[];
    services: CourseServiceItem[];
    processes: CourseProcessItem[];
    hardwares: CourseHardwareItem[];
    classSessions: CourseClassSessionItem[];
};

export type CreatePayload = {
    name: string;
    status: COURSE_STATUS;
    thumbnail: string | null;
    description: string | null;
    academicStartDate: string;
    academicEndDate: string;
    courseFee: number;
    discountedFee: number;
    minSalePrice: number;
    salesStartDate: string;
    availableToSales: boolean;
    availableOnStore: boolean;
    sessionStartYear: string;
    sessionEndYear: string;
    group?: string;
    phase: string;
    type: string;
    branding: string;
    term: string;
    targetExam: string;
    showWithTax?: boolean;
    promotionalVideoId?: number;
    promotionalVideo?: {
        internalName: string;
        source: 'VIMEO' | 'VDOCIPHER' | 'VDOCIPHER_SECURE' | 'YOUTUBE';
        videoId: string;
        durInSec: number;
    };
    orderEnrollment: number,
    orderStore: number
};

// Set related
export type CreateCourseSetItem = {
    setId: number;
    isPrimary: boolean;
    order: number;
};

// Service related
export type CreateCourseServiceItem = {
    serviceId: number;
    isDefault: boolean;
};

export type CourseServiceItem = {
    id: number;
    name: string;
    serviceId: number;
    isDefault: boolean;
};

export type ServiceItem = {
    id: number;
    name: string;
};

// Class target related
export type CreateCourseClassTargetItem = {
    classId: number;
    targetId: number;
};
export type TargetItem = ServiceItem;
export type ClassItem = ServiceItem;
export type CourseClassTargetitem = {
    courseClassTargetId: number;
    classId: number;
    className: string;
    targetId: number;
    targetName: string;
};

// Process related
export type CreateCourseProcessItem = {
    processId: number;
};
export type ProcessItem = {
    id: number;
    processName: string;
    roleId: number;
};
export type CourseProcessItem = {
    id: number;
    processId: number;
    name: string;
};

// Hardware related
export type CreateCourseHardwareItem = {
    hardwareId: number;
};
export type CourseHardwareItem = {
    id: number;
    name: string;
    hardwareId: number;
    minAmount: number;
};
export type HardwareItem = ServiceItem & { minAmount: number };

export enum CourseStatus {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export type CourseClassSessionItem = {
    id?: number;
    classId?: number;
    sessionStart?: string;
    sessionEnd?: string;
    targetExamId?: number;
};

export type CourseLoader = {
    courses: CourseType[];
    total: number;
    page: number;
    limit: number;
};
