import { BACKEND_URLS } from '../config';
import { UserPermission } from '../types/permission.types';
import { UserRole } from '../types/role.types';
import {
    UserDetailsResponse,
    UserContactPayload,
    UserContactResponse,
    UserAddressPayload,
    UserAddressResponse,
    User,
} from '../types/user-profille-types';
import ApiService, { ApiResponse } from './ApiServices/api-service';

class UserService extends ApiService {
    constructor() {
        super(BACKEND_URLS.GET_USERS);
    }

    getUsersCount({
        classes,
        targets,
    }: {
        classes: number[];
        targets: number[];
    }): ApiResponse<{ count: number }> {
        return this.postData('/count', { classes, targets });
    }

    getFullDetails(id: number): ApiResponse<{ user: UserDetailsResponse }> {
        return this.getData(`/${id}`);
    }

    updateDetails(userId: number, data: any): ApiResponse {
        return this.patchData(`/${userId}/user-profile`, data);
    }

    updateAcademics(userId: number, data: any): ApiResponse {
        return this.patchData(`/${userId}/user-academic`, data);
    }

    addContact(
        userId: number,
        data: UserContactPayload
    ): ApiResponse<{ contact: UserContactResponse }> {
        return this.postData(`/${userId}/contact`, data);
    }
    updateContact(userId: number, contactId: number, data: Partial<UserContactPayload>) {
        return this.putData(`/${userId}/contact/${contactId}`, data);
    }
    deleteContact(userId: number, contactId: number) {
        return this.deleteData(`/${userId}/contact/${contactId}`);
    }

    addAddress(
        userId: number,
        data: UserAddressPayload
    ): ApiResponse<{ address: UserAddressResponse }> {
        return this.postData(`/${userId}/address`, data);
    }

    updateAddress(userId: number, id: number, data: Partial<UserAddressPayload>) {
        return this.putData(`/${userId}/address/${id}`, data);
    }
    deleteAddress(userId: number, addressId: number) {
        return this.deleteData(`/${userId}/address/${addressId}`);
    }

    getMentors(): ApiResponse {
        return this.getData(`?isStaff=true&role=mentor`);
    }

    getStaff(): ApiResponse {
        return this.getData(`?isStaff=true`)
    }

    getAllUsers(query?: string): ApiResponse<{ users: User[] }> {
        const url = query ? query : '';
        return this.getData(`?${url}`);
    }

    deleteUser(id: number) {
        return this.deleteData(`/${id}`);
    }

    createUser(data: {
        dateOfBirth?: string;
        gender?: string;
        isStaff?: boolean;
        name?: string;
        profileUrl?: string;
        username?: string;
        utmSource?: string;
    }): ApiResponse {
        return this.postData('', data);
        // return { data };
    }

    updateUser(
        id: number,
        data: {
            dateOfBirth?: string;
            gender?: string;
            isStaff?: boolean;
            name?: string;
            profileUrl?: string;
            username?: string;
            utmSource?: string;
        }
    ) {
        return this.patchData(`/${id}/user-profile`, data);
    }

    getUserRoles(userId: number): ApiResponse<{ roles: UserRole[] }> {
        return this.getData(`/${userId}/roles`);
    }
    assignRole(userId: number, roleId: number): ApiResponse<{ role: UserRole }> {
        return this.postData(`/${userId}/roles`, { roleId });
    }
    removeRole(userId: number, roleId: number): ApiResponse<{ count: number }> {
        return this.deleteData(`/${userId}/roles/${roleId}`);
    }

    getUserPermissions(userId: number): ApiResponse<{ permissions: UserPermission[] }> {
        return this.getData(`/${userId}/permissions`);
    }

    assignPermission(
        userId: number,
        permissionId: number
    ): ApiResponse<{ permission: UserPermission }> {
        return this.postData(`/${userId}/roles`, { permissionId });
    }
    removePermission(userId: number, permissionId: number): ApiResponse<{ count: number }> {
        return this.deleteData(`/${userId}/roles/${permissionId}`);
    }
}

export default new UserService();
