import { useEffect, useState } from 'react';
import { RoleResponse } from '../../types/role.types';
import { PermissionResponse } from '../../types/permission.types';
import roleService from '../../services/ApiServices/role-service';
import permissionService from '../../services/ApiServices/permission-service';
import toast from 'react-hot-toast';

const CreateEditRole = ({ id, onSuccess }: { id?: number; onSuccess: () => void }) => {
    const [role, setRole] = useState<Partial<RoleResponse> | null>(null);
    const [permissions, setPermissions] = useState<PermissionResponse[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            roleService.getRoleById(id).then((res) => {
                if (res.status === 'success') {
                    setRole(res.data.role);
                    setSelectedPermissions(res.data.role.permissions.map((p) => p.id));
                }
            });

            permissionService.getAllPermission().then((res) => {
                if (res.status === 'success') setPermissions(res.data.permissions);
            });
        }
    }, [id]);

    const handleInputChange = (key: keyof RoleResponse, value: string) => {
        setRole((prev) => ({ ...prev, [key]: value }));
    };

    const handlePermissionToggle = (permissionId: number) => {
        if (selectedPermissions.includes(permissionId)) {
            setSelectedPermissions((prev) => prev.filter((id) => id !== permissionId));
            removePermission(permissionId);
        } else {
            setSelectedPermissions((prev) => [...prev, permissionId]);
            addPermission(permissionId);
        }
    };

    const handleSubmit = () => {
        if (!role?.name) return toast.error('Name is required');
        if (!role.slug) return toast.error('Slug is required');
        const trimmedPayload = {
            name: role.name?.trim(),
            slug: role.slug?.trim(),
        };
        if (id) {
            roleService.updateRole(id, trimmedPayload).then((res) => {
                if (res.status === 'success') {
                    toast.success('Role updated successfully');
                    onSuccess();
                }
            });
        } else {
            roleService.createRole(trimmedPayload).then((res) => {
                if (res.status === 'success') {
                    toast.success('Role created successfully');
                    onSuccess();
                }
            });
        }
    };

    const addPermission = (permissionId: number) => {
        if (id && permissionId) {
            roleService.addPermission(id, permissionId).then((res) => {
                if (res.status === 'success') toast.success(res.message);
            });
        }
    };

    const removePermission = (permissionId: number) => {
        if (id && permissionId) {
            roleService.removePermission(id, permissionId).then((res) => {
                if (res.status === 'success') toast.success(res.message);
            });
        }
    };

    return (
        <div className="container mt-2">
            <div className="mb-3">
                <label htmlFor="roleName" className="form-label">
                    Role Name
                </label>
                <input
                    type="text"
                    id="roleName"
                    className="form-control"
                    value={role?.name || ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Enter role name"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="roleSlug" className="form-label">
                    Role Slug
                </label>
                <input
                    type="text"
                    id="roleSlug"
                    className="form-control"
                    value={role?.slug || ''}
                    onChange={(e) => handleInputChange('slug', e.target.value)}
                    placeholder="Enter role slug"
                />
            </div>

            {id && (
                <div className="mb-3">
                    <h5>Permissions</h5>
                    <div className="row">
                        {permissions.map((permission) => (
                            <div key={permission.id} className="col-md-4 mb-2">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`permission-${permission.id}`}
                                        checked={selectedPermissions.includes(permission.id)}
                                        onChange={() => handlePermissionToggle(permission.id)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`permission-${permission.id}`}
                                    >
                                        {permission.slug}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <button className="btn btn-primary" onClick={handleSubmit}>
                {id ? 'Update Role' : 'Create Role'}
            </button>
        </div>
    );
};

export default CreateEditRole;
