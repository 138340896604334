import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import toast from 'react-hot-toast';
import { debounce } from '../../utils';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS, ENDPOINT_HOST, MEDIA_BASE_URL } from '../../config';
import courseService from '../../services/ApiServices/course-service';
import {
    EnrolledStudent,
    EnrollmentFilteresInterface,
    metaDataInterface,
} from '../../types/enrolled-student';
import UploadTTModal from '../modals/upload-tt-modal';
import SendNotifcationModal from '../modals/send-notification-modal';
import axios from 'axios';
// import { convertISTtoUTC } from '../../utils/helpers';
import AssignSetModal from '../modals/assign-set-modal';

const colors = ['#ff7979', '#badc58', '#7ed6df', '#f9ca24'];

// Custom styles for MultiValue
const customStyles = {
    multiValue: (styles: any, { index }: any) => ({
        ...styles,
        backgroundColor: colors[index % colors.length],
        color: 'white',
        borderRadius: '4px',
    }),
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'white',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: 'white',
        ':hover': {
            backgroundColor: 'darkred',
            color: 'white',
        },
    }),
};

type campaginType = {
    name: string;
    title: string;
    message: string;
    iconUrl: string;
    mediaUrl: string;
    cta: string;
    ctaParam: string;
    category: string;
    sendAt: string;
};

const MultiValue = (props: any) => {
    const color = colors[props.index % colors.length];
    return (
        <components.MultiValue {...props}>
            <span
                style={{
                    backgroundColor: color,
                    color: 'white',
                    padding: '0 5px',
                    borderRadius: '4px',
                }}
            >
                {props.data.label}
            </span>
        </components.MultiValue>
    );
};

interface EnrolledStudentsHeaderInterface {
    setFilter: React.Dispatch<React.SetStateAction<EnrollmentFilteresInterface>>;
    metaData: metaDataInterface;
    filters: EnrollmentFilteresInterface;
    selectedEnrollmentIds: number[];
    selectedStudentIds: number[];
    clearEnrollmentIds: () => void;
    handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
    allSelected: boolean;
    filterdData: EnrolledStudent[];
}

function EnrolledStudentsHeader({
    setFilter,
    selectedStudentIds,
    metaData,
    filters,
    selectedEnrollmentIds,
    clearEnrollmentIds,
    allSelected,
    handleSelectAll,
    filterdData,
}: EnrolledStudentsHeaderInterface) {
    const [allCourses, setAllCourses] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [mentors, setMentors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showModalTT, setShowModalTT] = useState(false);
    const [showModalNot, setShowModalNot] = useState(false);
    const [isAssignSet, setIsAssignSet] = useState(false);
    const [timeTableUrl, setTimeTableUrl] = useState('');
    const [timeTableName, setTimeTableName] = useState('');
    const [titleName, setTitleName] = useState('');
    const [message, setmessage] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [campaignData, setCampaignData] = useState<campaginType>({
        name: '',
        title: '',
        message: '',
        iconUrl: '',
        mediaUrl: '',
        cta: '',
        ctaParam: '',
        category: '',
        sendAt: '',
    });

    const isSameCourse = () => {
        const selectedStudents =
            filterdData?.filter((student) =>
                selectedEnrollmentIds?.includes(student.enrollment_id)
            ) || [];

        const areCoursesSame = selectedStudents.every(
            (enrollment, _, array) => enrollment.course_name === array[0].course_name
        );

        if (!areCoursesSame) {
            toast.error('All selected enrollments must have the same course');
            return false;
        }

        return true;
    }

    const handleShowModalTT = () => {
        if (isSameCourse()) {
            setShowModalTT(true);
        }
    };

    // Hide the modal and reset state variables
    const handleHideModalTT = () => {
        setShowModalTT(false);
        clearEnrollmentIds();
    };

    const handleShowModalNot = () => setShowModalNot(true);
    // Hide the modal and reset state variables
    const handleHideModalNot = () => {
        setShowModalNot(false);
        clearEnrollmentIds();
    };
    const handleConfirmTT = async (url: string, name: string, ids: number[]) => {
        const uniqueEnrollmentIds = new Set(selectedEnrollmentIds);
        const uniqueEnrollmentIdsArray = Array.from(uniqueEnrollmentIds);
        const requestData = {
            timeTableUrl: url,
            timeTableName: name,
            enrollmentIds: uniqueEnrollmentIdsArray,
        };

        try {
            const response = await axiosClient.post(`${ENDPOINT_HOST}/enrollments/timetables`, {
                ...requestData,
            });

            if (response.status === 201) {
                toast.success('Success');
            }
        } catch (error) {
            console.error('Error calling API:', error);
        }
        setTimeTableUrl('');
        setTimeTableName('');
        setShowModalTT(false);
        clearEnrollmentIds();
    };

    const handleConfirmNot = async (
        url: string,
        titleName: string,
        studentIds: number[],
        message: string,
        campaignData: campaginType
    ) => {
        if (!titleName || !message || !selectedImage || !campaignData || !campaignData.sendAt) {
            toast.error('All fields are required');
            return;
        }
        const uniqueStudentIds = new Set(selectedStudentIds);
        const uniqueStudentIdsArray = Array.from(uniqueStudentIds);
        const templateRequestData = {
            name: 'EnrollMent Notification template',
            title: titleName,
            body: JSON.stringify({
                description: message,
            }),
            imageUrl: selectedImage,
            cta: campaignData.cta === 'EXTERNAL' ? 'external' : 'internal',
            ctaParams: {
                ...(campaignData.cta === 'EXTERNAL'
                    ? { url: campaignData.ctaParam }
                    : { screen: campaignData.cta }),
            },
        };

        try {
            //step 1
            const templateResponse = await axiosClient.post(
                `${ENDPOINT_HOST}/notifications/templates`,
                templateRequestData
            );
            if (templateResponse.status === 201) {
                const pushTemplateId = templateResponse.data.data.template.id;
                // Step 2
                const campaignRequestData = {
                    name: 'EnrollMent Notifiexportcation campaign',
                    scheduledAt: campaignData.sendAt,
                    pushTemplateId,
                };

                const campaignResponse = await axiosClient.post(
                    `${ENDPOINT_HOST}/notifications/campaigns`,
                    campaignRequestData
                );
                if (campaignResponse.status === 201) {
                    const campaignId = campaignResponse.data.data.campaign.id;

                    // Step 3
                    const userAssignmentData = {
                        users: uniqueStudentIdsArray,
                    };

                    const userResponse = await axiosClient.post(
                        `${ENDPOINT_HOST}/notifications/campaigns/${campaignId}/users`,
                        userAssignmentData
                    );
                    if (userResponse.status === 201) {
                        toast.success('Notification sent successfully');
                    } else {
                        toast.error('Failed to assign users to the campaign');
                    }
                } else {
                    toast.error('Failed to schedule the campaign');
                }
            } else {
                toast.error('Failed to create notification template');
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                // Handle AxiosError
                console.error('Axios Error:', error.response?.data);
                toast.error(`API Error: ${error.response?.data?.message || 'Unknown error'}`);
            } else if (error instanceof Error) {
                // Handle other types of errors (e.g., runtime errors)
                console.error('Error:', error.message);
                toast.error(`Unexpected Error: ${error.message}`);
            } else {
                // Handle any other unknown errors
                console.error('Unknown Error:', error);
                toast.error('An unknown error occurred');
            }
        }
    };

    const handleAssignSet = async () => {
        if (isSameCourse()) {
            setIsAssignSet(true);
        }
    }

    const debouncedSearch = useCallback(
        debounce((val: string) => setFilter((prev: any) => ({ ...prev, name: val })), 200),
        [setFilter]
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [coursesResponse, mentorsResponse] = await Promise.all([
                    courseService.getAllCourses(),
                    axiosClient.get(BACKEND_URLS.GET_USERS + '?isStaff=true&role=mentor'),
                ]);

                if (coursesResponse.status === 'success') {
                    setAllCourses(
                        coursesResponse.data.courses.map((el) => ({
                            label: el.name,
                            value: el.id,
                        })) as any
                    );
                    setAllGroups(
                        coursesResponse.data.courses
                            .filter((el: any) => el.group)
                            .map((el: any) => ({ value: el.group, label: el.group })) as any
                    );
                }

                const mentorOpts = mentorsResponse.data.data.users.map((user: any) => ({
                    value: user.id,
                    label: `${user.username} (${user.name})`,
                }));
                setMentors(mentorOpts);
            } catch (error) {
                toast.error('Error fetching data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="p-4 bg-light">
            <Row className="mb-4">
                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="search" label="Search by Name/Number/Email">
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Search by Name/Number/Email"
                            onChange={(e) => debouncedSearch(e.target.value)}
                        />
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="academicStatus" label="Academic Status">
                        <Form.Select
                            onChange={(e) =>
                                setFilter((prev: any) => ({ ...prev, status: e.target.value }))
                            }
                            value={filters.status}
                        >
                            <option value="ALL">All</option>
                            <option value="HASMENTORSHIP">Active With Mentorship</option>
                            <option value="NOMENTORSHIP">Active Without Mentorship</option>
                            <option value="INACTIVE">Inactive</option>
                            <option value="EXPIRED">Course Ended</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="onboardingStatus" label="Onboarding Status">
                        <Form.Select
                            onChange={(e) =>
                                setFilter((prev: any) => ({ ...prev, process: e.target.value }))
                            }
                            value={filters.process}
                        >
                            <option value="ALL">
                                All (
                                {metaData.onboarded + metaData.in_process + metaData.not_started})
                            </option>
                            <option value="ONBOARDED">Onboarded ({metaData.onboarded})</option>
                            <option value="INPROGRESS">In Progress ({metaData.in_process})</option>
                            <option value="NOTSTARTED">Not Started ({metaData.not_started})</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Courses"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                course_id: selected.map((s: any) => s.value),
                            }))
                        }
                        options={allCourses}
                        isMulti
                        styles={customStyles}
                        components={{ MultiValue }}
                        isLoading={isLoading}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Groups"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                group: selected.map((s: any) => s.value),
                            }))
                        }
                        options={allGroups}
                        isMulti
                        styles={customStyles}
                        isLoading={isLoading}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Mentors"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                mentor_id: selected.map((s: any) => s.value),
                            }))
                        }
                        options={mentors}
                        isMulti
                        styles={customStyles}
                        isLoading={isLoading}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} className="mt-2">
                    <div className="position-relative mt-4 " style={{ display: 'row' }}>
                        <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleSelectAll}
                        // style={{ marginBottom: '2rem' }}
                        />
                        <span className="ps-2">
                            {selectedEnrollmentIds.length > 0
                                ? `Students selected: ${selectedEnrollmentIds.length}`
                                : 'Select All'}
                        </span>
                    </div>
                </Col>
                {/* <Col xs={12} sm={6} md={4} className="mb-2">
                    <Button
                        size="sm"
                        variant="primary"
                        type="button"
                        style={{ width: '140px' }}
                        onClick={handleShowModalTT}
                        disabled={!(selectedEnrollmentIds.length > 0)}
                    >
                        Upload Time Table
                    </Button>
                </Col>
                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Button
                        size="sm"
                        variant="primary"
                        type="button"
                        style={{ width: '140px' }}
                        onClick={handleShowModalNot}
                        disabled={!(selectedStudentIds.length > 0)}
                    >
                        Send Notfication
                    </Button>
                </Col> */}
                <Col className='d-flex justify-content-end align-items-center gap-3'>
                    <Button
                        size="sm"
                        variant="primary"
                        type="button"
                        style={{ width: '140px' }}
                        onClick={handleShowModalTT}
                        disabled={!(selectedEnrollmentIds.length > 0)}
                    >
                        Upload Time Table
                    </Button>
                    <Button
                        size="sm"
                        variant="primary"
                        type="button"
                        style={{ width: '140px' }}
                        onClick={handleShowModalNot}
                        disabled={!(selectedStudentIds.length > 0)}
                    >
                        Send Notfication
                    </Button>
                    <Button
                        size="sm"
                        variant="primary"
                        type="button"
                        style={{ width: '140px' }}
                        onClick={handleAssignSet}
                        disabled={!(selectedStudentIds.length > 0)}
                    >
                        Assign Set
                    </Button>
                </Col>

                <UploadTTModal
                    show={showModalTT}
                    onHide={handleHideModalTT}
                    onConfirm={handleConfirmTT}
                    onFileChange={(key) => {
                        if (key) {
                            const URL = `${MEDIA_BASE_URL}${key}`;
                            setTimeTableUrl(URL);
                        } else {
                            console.error('Error: Key received in onFileChange is undefined');
                        }
                    }}
                    onNameChange={(name) => setTimeTableName(name)}
                    enrollmentIds={selectedEnrollmentIds}
                />
                <SendNotifcationModal
                    show={showModalNot}
                    onHide={handleHideModalNot}
                    onConfirm={handleConfirmNot}
                    onFileChange={(key) => {
                        if (key) {
                            const URL = `${MEDIA_BASE_URL}${key}`;
                            setSelectedImage(URL);
                        } else {
                            console.error('Error: Key received in onFileChange is undefined');
                        }
                    }}
                    onTitleChange={(name) => setTitleName(name)}
                    onMessageChange={(name) => setmessage(name)}
                    oncampaignDataChange={(name) => setCampaignData(name)}
                    studentIds={selectedStudentIds}
                />
                {isAssignSet &&
                    <AssignSetModal
                        show={isAssignSet}
                        onHide={() => setIsAssignSet(false)}
                        selectedEnrollmentIds={selectedEnrollmentIds}
                        filterdData={filterdData}
                        clearEnrollmentIds={clearEnrollmentIds}
                    />
                }
            </Row>
        </div>
    );
}

export default EnrolledStudentsHeader;
