import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '../../services/user-service';
import { UserDetailsResponse } from '../../types/user-profille-types';
import LoadingIndicator from '../LoadingIndicator';
import AcadmicDetailsInfo from '../mentor-enrollment/acadmic-details-info';
import AddressDetails from '../mentor-enrollment/address-info';
import ContactDetailsInfo from '../mentor-enrollment/contact-details-info';
import PersonalInfoForm from '../mentor-enrollment/personal-info';
import {
    hardwaresInterface,
    hardwearNameInterface,
    userEnrollmentsInterface,
    userEnrollmentsServices,
    userInterface,
} from '../../types/ApiTypes';

function StudentDetails({
    children,
    isDisabled = false,
    courseId,
    userEnrollments,
    enrollmentService,
    hardwares,
    salesPerson,
}: {
    children?: React.ReactNode;
    isDisabled?: boolean;
    courseId?: number | null;
    userEnrollments?: userEnrollmentsInterface[];
    enrollmentService?: userEnrollmentsServices[];
    hardwares?: (hardwaresInterface & hardwearNameInterface)[];
    salesPerson?: userInterface | null;
}) {
    const { state } = useLocation();
    const studentId = state?.studentId;
    const [studentInfo, setStudentInfo] = useState<UserDetailsResponse>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (studentId) {
            userService
                .getFullDetails(+studentId)
                .then((res) => {
                    if (res.status === 'success') {
                        setStudentInfo(res.data.user);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [studentId]);

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!studentId) {
        return <>Student not found. ER7845129</>;
    }

    return (
        <div>
            <PersonalInfoForm
                details={studentInfo!}
                userId={+studentId}
                isDisabled={isDisabled}
                courseId={courseId}
                userEnrollments={userEnrollments}
                enrollmentService={enrollmentService}
                hardwares={hardwares}
                salesPerson={salesPerson}
            />
            <AcadmicDetailsInfo
                academics={studentInfo?.userAcademicInfo?.[0]!}
                userId={+studentId}
                isDisabled={isDisabled}
            />
            <ContactDetailsInfo
                userContacts={studentInfo?.userContacts!}
                userId={+studentId}
                isDisabled={isDisabled}
            />
            <AddressDetails
                userAddresses={studentInfo?.userAddress!}
                userId={+studentId}
                isDisabled={isDisabled}
            />
        </div>
    );
}

export default StudentDetails;
