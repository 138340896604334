import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { ContentConstants } from '../../Redux/content/content-constants';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { Formik } from 'formik';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { uploadVideoOnVdoCipherDirect } from '../../utils/helpers';

const UploadVideo = () => {
    const platforms = [
        { optionValue: 'VDOCIPHER_SECURE', optionName: 'SECURE' },
        { optionValue: 'VDOCIPHER', optionName: 'NON_SECURE' },
    ];

    const schema = yup.object().shape({
        securityLevelValue: yup.string().required('Security level is required'),
        file: yup.mixed().required('A file is required'),
    });

    const [loading, setLoading] = useState(false);

    const [videoProgress, setVideoProgress] = useState(0);

    const selectedChapter = useSelector<any>((state) => state.ContentReducer.selectedChapter) as {
        level2Id: number;
        level2Name: number;
    };

    const { nodeId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUploadVideo = async (values: any) => {
        setLoading(true);

        const { videoId, fileName } = (await uploadVideoOnVdoCipherDirect({
            file: values.file,
            vdoCipherId: values.securityLevelValue,
            onUploadProgress: setVideoProgress,
        })) as { videoId: string; fileName: string };

        const assetId = await axiosClient.post(BACKEND_URLS.ASSET_CREATE, {
            source: values.securityLevelValue,
            internalName: values.file.name,
            fileName: values.file.name,
            durInSec: 0,
            videoId: `${videoId}`,
        });

        const content = {
            isNew: true,
            id: -1,
            name: values.file.name,
            assetType: 'VIDEO',
            assetId: assetId.data.data.id,
            type: '',
            level2Id: selectedChapter.level2Id,
            level2Name: selectedChapter.level2Name,
            level3Id: null,
            parentAssetType: 'null',
            parentAssetId: 0,
            isFree: false,
            createdOn: '',
            assetData: {
                id: assetId.data.data.id,
                source: values.securityLevelValue,
                videoId: videoId,
                thumbnailUrlLr: '',
                thumbnailUrlHr: '',
                durInSec: 0,
                internalName: '',
                fileName: values.file.name,
            },
        };

        dispatch({ type: ContentConstants.ADD_CONTENT, payload: content });
        dispatch({ type: ModalsConstants.IS_Modal, payload: false });
        navigate(
            FRONTEND_URLS.CONTENTS.EDIT_NODE +
                `/${nodeId}` +
                '/' +
                FRONTEND_URLS.CONTENTS.ASSET +
                '/' +
                content.id
        );
        setLoading(false);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleUploadVideo}
            initialValues={{
                // internalName: '',
                securityLevelValue: platforms[0].optionValue,
                file: null,
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="p-2">
                    <Form.Group className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Security Level</Form.Label>
                        <div className="col-sm-9">
                            <Form.Select
                                name="securityLevelValue"
                                onChange={handleChange}
                                value={values.securityLevelValue}
                                isInvalid={
                                    touched.securityLevelValue && !!errors.securityLevelValue
                                }
                            >
                                {platforms.map((option, index) => (
                                    <option key={index} value={option.optionValue}>
                                        {option.optionName}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.securityLevelValue}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="floatingInput" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">
                            Upload Video File
                        </Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="file"
                                name="file"
                                onChange={(event) => {
                                    const file = (event.target as HTMLInputElement).files?.[0];
                                    setFieldValue('file', file);
                                }}
                                accept="video/mp4,video/x-m4v,video/*"
                                isInvalid={touched.file && !!errors.file}
                            />
                            <div className="my-2">
                                <ProgressBar
                                    now={videoProgress}
                                    label={`${videoProgress}%`}
                                    animated
                                    striped
                                />
                            </div>
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.file}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Button type="submit" variant="primary" className="mt-3" disabled={loading}>
                        Add
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default UploadVideo;
