import { useEffect, useState } from 'react';
import { Button, Form, ProgressBar, Table } from 'react-bootstrap';
import { nodeContentMappingInterface, SessionType } from '../../types/LiveTypes';
import { VideoSource } from '../../types/types';
import {
    convertISTtoUTC,
    convertUTCtoIST,
    pdfUploadHelper,
    uploadVideoOnVdoCipherDirect,
} from '../../utils/helpers';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS } from '../../config';
import toast from 'react-hot-toast';
import content from '../../services/content';

interface LiveManagementInterface {
    closeModal: () => void;
    sessionId: string;
    contentNodes: nodeContentMappingInterface[];
    zoomSessionId: number;
    videoId: string | null;
    pdfId: number | null;
    hasPDF: boolean;
    isRecorded: boolean;
    // handleUpdateStatusOfRecordingAndPdf: (
    //     videoId: number | null,
    //     pdfId: number | null,
    //     id: number,
    //     pdfName: string | null,
    //     videoName: string | null
    // ) => void;
    videoName: string | null;
    pdfName: string | null;
    type: SessionType;
    actualStartTime?: string;
    actualEndTime?: string;
    studentCount?: number;
}

interface contentNodeRestrucureInterface {
    contentId: number;
    nodes: {
        id: number;
        internalName: string | null;
    }[];
    video: number[];
    pdf: number[];
}

const LiveManagementContent = ({
    closeModal,
    sessionId,
    zoomSessionId,
    contentNodes,
    videoId,
    pdfId,
    hasPDF,
    isRecorded,
    // handleUpdateStatusOfRecordingAndPdf,
    videoName,
    pdfName,
    type,
    actualStartTime,
    actualEndTime,
    studentCount,
}: LiveManagementInterface) => {
    const [showVideoInput, setShowVideoInput] = useState<boolean>(false);
    const [videoSource, setVideoSource] = useState<string>('');
    const [videoProgress, setVideoProgress] = useState(0);
    const [actStartTime, setActualStartTime] = useState(actualStartTime);
    const [actEndTime, setActualEndTime] = useState(actualEndTime);
    const [stdCount, setStudentCount] = useState(studentCount);

    const [videoKey, setVideoKey] = useState<string | null>(videoId);
    const [pdfProgress, setPdfProgress] = useState(0);
    const [pdfUrl, setPdfUrl] = useState('');
    const [uploadingVideo, setUploadingVideo] = useState(false);

    const handleVideoUpload = async (file: File) => {
        const source = videoSource == '3' ? VideoSource.VIMEO : VideoSource.VDOCIPHER;
        const data = await uploadVideoOnVdoCipherDirect({
            file,
            vdoCipherId: source,
            onUploadProgress: setVideoProgress,
        });
        if (data?.videoId) {
            await updateSession({ videoId: data.videoId, source, name: file.name });
        }
    };

    const updateSession = async ({
        videoId,
        source,
        name,
        actualStartTime,
        actualEndTime,
        studentCount,
        durInSec = 0,
    }: {
        source?: VideoSource;
        videoId?: string;
        name?: string;
        actualStartTime?: string;
        actualEndTime?: string;
        studentCount?: number;
        durInSec?: number;
    }) => {
        let recordingId;
        if (source && videoId) {
            try {
                if (!durInSec && !name) {
                    const videoDetails = await content.fetchVideoDetails(videoId, source);
                    name = videoDetails.fileName;
                    durInSec = videoDetails.durInSec;

                    const assetRes = await axiosClient.post(BACKEND_URLS.ASSET_CREATE, {
                        source,
                        internalName: name,
                        fileName: name,
                        durInSec: durInSec,
                        videoId,
                    });
                    recordingId = assetRes.data.data.id;
                }
            } catch (error) {
                setUploadingVideo(false);
                if (error instanceof Error) {
                    return toast.error(error.message);
                }
                return toast.error('error while fetching video details');
            }
        }
        try {
            await axiosClient.put(`${BACKEND_URLS.CREATE_SESSION}/${zoomSessionId}`, {
                recordingId,
                sessionId: sessionId ?? undefined,
                actualStartTime,
                actualEndTime,
                studentCount,
            });
            setShowVideoInput(false);
            if (videoId) {
                setVideoKey(videoId);
                setUploadingVideo(false);
            }
        } catch (error: any) {
            toast.error(`Error updating session: ${error}`);
        }
        return;
    };

    const handlePdfUpload = async (file: File) => {
        const pdfUrl = await pdfUploadHelper(file, setPdfProgress);
        setPdfUrl(pdfUrl);

        const assetId = await axiosClient.post(BACKEND_URLS.ASSET_CREATE_PDF, {
            internalName: file.name,
            fileName: file.name,
            decryptedFileUrl: `${pdfUrl}`,
        });

        try {
            await axiosClient.put(`${BACKEND_URLS.CREATE_SESSION}/${zoomSessionId}`, {
                pdfId: assetId.data.data.id,
                sessionId: sessionId || undefined,
            });
        } catch (error) {
            toast.error(`Error updating session: ${error}`);
        }
    };

    return (
        <>
            {isRecorded && (
                <Form.Group controlId="pdfUpload" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Upload Video File</Form.Label>
                    <div className="col-sm-9">
                        {showVideoInput ? (
                            <>
                                <Form.Select
                                    className="mb-2"
                                    aria-label="Default select example"
                                    onChange={(e) => setVideoSource(e.target.value)}
                                    value={videoSource}
                                >
                                    <option value="" disabled>
                                        Select Source
                                    </option>
                                    <option value="1">VdoCipher(Upload Video)</option>
                                    <option value="2">VdoCipher(Add Video id)</option>
                                    <option value="3">Vimeo</option>
                                </Form.Select>
                                {videoSource &&
                                    (videoSource === '1' ? (
                                        <>
                                            <Form.Control
                                                type="file"
                                                name="videoFile"
                                                accept="video/*"
                                                onChange={(event) => {
                                                    const input = event.target as HTMLInputElement;
                                                    const file = input.files?.[0];
                                                    if (file) {
                                                        handleVideoUpload(file);
                                                    }
                                                }}
                                            />
                                            {videoProgress > 0 && (
                                                <ProgressBar
                                                    now={videoProgress}
                                                    label={`${videoProgress}%`}
                                                    animated
                                                    striped
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Form.Control
                                                type={videoSource == '3' ? 'number' : 'text'}
                                                onChange={(e) => setVideoKey(e.target.value)}
                                                placeholder="Enter video id"
                                                className="mb-2"
                                            />
                                            <Button
                                                onClick={() => {
                                                    if (!videoSource || !videoKey) {
                                                        toast.error(
                                                            'Please select video source and enter video id'
                                                        );
                                                        return;
                                                    }
                                                    setUploadingVideo(true);
                                                    updateSession({
                                                        videoId: videoKey,
                                                        source:
                                                            videoSource == '3'
                                                                ? VideoSource.VIMEO
                                                                : VideoSource.VDOCIPHER,
                                                    });
                                                }}
                                                disabled={uploadingVideo}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    ))}

                                {videoName && (
                                    <div>
                                        Video already uploaded: <strong>{videoName}</strong>
                                    </div>
                                )}
                            </>
                        ) : videoKey ? (
                            <>
                                Video uploaded:{' '}
                                <strong>
                                    {videoName} ({videoKey})
                                </strong>
                                <Button
                                    onClick={() => {
                                        setVideoProgress(0);
                                        setShowVideoInput(true);
                                    }}
                                >
                                    Replace
                                </Button>
                            </>
                        ) : (
                            <>
                                Awaiting Recording from zoom. <strong>{videoName}</strong>
                                <Button
                                    onClick={() => {
                                        setVideoProgress(0);
                                        setShowVideoInput(true);
                                    }}
                                >
                                    Upload Manually
                                </Button>
                            </>
                        )}
                    </div>
                </Form.Group>
            )}
            {hasPDF && (
                <Form.Group controlId="pdfUpload" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Upload PDF File</Form.Label>
                    <div className="col-sm-9">
                        <Form.Control
                            type="file"
                            name="pdfFile"
                            accept="application/pdf"
                            onChange={(event) => {
                                const input = event.target as HTMLInputElement;
                                const file = input.files?.[0];
                                if (file) {
                                    handlePdfUpload(file);
                                }
                            }}
                        />
                        {pdfUrl ? (
                            <div>PDF Url: {pdfUrl}</div>
                        ) : (
                            <ProgressBar
                                now={pdfProgress}
                                label={`${pdfProgress}%`}
                                animated
                                striped
                            />
                        )}
                        {pdfName && (
                            <div>
                                PDF already uploaded: <strong>{pdfName}</strong>
                            </div>
                        )}
                    </div>
                </Form.Group>
            )}

            {type !== 'ZOOM' && (
                <Form
                    onSubmit={() => {
                        updateSession({
                            actualEndTime: actEndTime,
                            actualStartTime: actStartTime,
                            studentCount: stdCount,
                        });
                    }}
                >
                    <Form.Group controlId="studentCount" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Student Count</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="number"
                                value={stdCount}
                                onChange={(event) => {
                                    const count = event.target.value;
                                    setStudentCount(+count);
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="actualStartTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">
                            Actual Start Time
                        </Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={actStartTime ? convertUTCtoIST(actStartTime) : ''}
                                onChange={(event) => {
                                    const time = event.target.value;
                                    setActualStartTime(convertISTtoUTC(time));
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="actualEndTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Actual End Time</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={actEndTime ? convertUTCtoIST(actEndTime) : ''}
                                onChange={(event) => {
                                    const time = event.target.value;
                                    setActualEndTime(convertISTtoUTC(time));
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary">
                        Save
                    </Button>
                </Form>
            )}

            {/* <Table striped bordered hover>
                <thead>
                    <tr>
                        {isRecorded && <th>Video</th>}
                        {hasPDF && <th>Pdf</th>}
                        <th>Node Id</th>
                    </tr>
                </thead>
                <tbody>
                    {contentNodeRestructure.map((item, rowIndex) =>
                        item.nodes.map((node, nodeIndex) => (
                            <tr key={`${rowIndex}-${nodeIndex}`}>
                                {nodeIndex === 0 && (
                                    <>
                                        {isRecorded && (
                                            <td
                                                rowSpan={item.nodes.length}
                                                style={{
                                                    color: item.video.length > 0 ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {item.video.length > 0 ? (
                                                    <FaCheckCircle
                                                        style={{
                                                            color: 'green',
                                                            fontSize: '1.5em',
                                                        }}
                                                    />
                                                ) : (
                                                    <FaTimesCircle
                                                        style={{ color: 'red', fontSize: '1.5em' }}
                                                    />
                                                )}
                                            </td>
                                        )}
                                        {hasPDF && (
                                            <td
                                                rowSpan={item.nodes.length}
                                                style={{
                                                    color: item.pdf.length > 0 ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {item.pdf.length > 0 ? (
                                                    <FaCheckCircle
                                                        style={{
                                                            color: 'green',
                                                            fontSize: '1.5em',
                                                        }}
                                                    />
                                                ) : (
                                                    <FaTimesCircle
                                                        style={{ color: 'red', fontSize: '1.5em' }}
                                                    />
                                                )}
                                            </td>
                                        )}
                                    </>
                                )}
                                <td>{node.internalName}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table> */}

            {/* <Button onClick={handleSubmit}>Submit</Button> */}
        </>
    );
};

export default LiveManagementContent;
