import { BACKEND_URLS } from '../config';
import ApiService, { ApiResponse } from './ApiServices/api-service';

type AssignSetPayload = {
    setId: number;
    enrollmentIds: number[];
};

class Enrollments extends ApiService {
    constructor() {
        super(BACKEND_URLS.ENROLLMENT);
    }

    getUserEnrollment(userId: string): ApiResponse {
        return this.getData(BACKEND_URLS.USER_ENROLLMENTS + `/${userId}`);
    }

    assignSet(data: AssignSetPayload) {
        return this.postData('/bulk-set-assign', data);
    }
}

export default new Enrollments();
