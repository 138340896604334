import React, { useState } from 'react';
import { Button, Form, Table, Card, Toast } from 'react-bootstrap';
import Select from 'react-select';
import { STATES } from '../../utils/constants';
import {
    ADDRESS_TYPE,
    UserAddressPayload,
    UserAddressResponse,
} from '../../types/user-profille-types';
import { BiPencil, BiTrash } from 'react-icons/bi';
import toast from 'react-hot-toast';
import userService from '../../services/user-service';

type Props = {
    userAddresses: UserAddressResponse[];
    userId: number;
    isDisabled?: boolean;
};

const AddressDetails = ({ userAddresses, userId, isDisabled = false }: Props) => {
    const [addresses, setAddresses] = useState<UserAddressPayload[]>(userAddresses);
    const [editId, setEditId] = useState<number | null>(null);
    const [editedAddress, setEditedAddress] = useState<UserAddressPayload | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [newAddress, setNewAddress] = useState<UserAddressPayload>({
        address: '',
        landmark: '',
        pincode: '',
        state: '',
        city: '',
        type: ADDRESS_TYPE.PERMANENT,
        isPrimary: false,
        isActive: true,
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editId !== null && editedAddress) {
            setEditedAddress((prev) => prev && { ...prev, [name]: value });
        } else {
            setEditedAddress((prev) => prev && { ...prev, [name]: value });
        }
    };

    const handleNewAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewAddress((prev) => ({ ...prev, [name]: value }));
    };

    const startEdit = (addressId: number) => {
        const editAddress = addresses?.find((address) => address?.id === addressId) || null;
        setEditId(addressId);
        setEditedAddress(editAddress);
    };

    const cancelEdit = () => {
        setEditId(null);
        setEditedAddress(null);
    };

    const saveEdit = () => {
        if (editId !== null && editedAddress) {
            const { id, ...rest } = editedAddress;
            
            const editPayload = {
                ...rest,
                address: rest?.address?.trim(),
                landmark: rest?.landmark?.trim() || '',
                pincode: rest?.pincode?.trim(),
                city: rest?.city?.trim(),
            };
            
            userService.updateAddress(userId, editId, editPayload).then((res) => {
                toast(res.message);
                if (res.status === 'success') {
                    setAddresses((prev) =>
                        prev.map((address) => (address.id === editId ? res.data.address : address))
                    );
                    setEditId(null);
                    showToastWithMessage('Address updated successfully!');
                }
            });
        }
    };

    const deleteAddress = (id: number) => {
        if (window.confirm('Do you want to delete this address?')) {
            userService.deleteAddress(userId, id).then((res) => {
                toast(res.message);
                if (res.status === 'success') {
                    setAddresses((prev) => prev.filter((_) => _.id !== id));
                    showToastWithMessage('Address deleted successfully!');
                }
            });
        }
    };

    const showToastWithMessage = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const addNewAddress = () => {
        if (!newAddress.pincode) {
            toast.error('Picode is required');
            return;
        }
        if (!newAddress.state) {
            toast.error('State is required');
            return;
        }
        if (!newAddress.city) {
            toast.error('City is required');
            return;
        }

        const payload = {
            ...newAddress,
            address: newAddress.address?.trim(),
            landmark: newAddress.landmark?.trim() || '',
            pincode: newAddress.pincode?.trim(),
            city: newAddress.city?.trim(),
        };

        userService.addAddress(userId, payload).then((res) => {
            toast(res.message);
            if (res.status === 'success') {
                setAddresses((prev) => [...prev, res.data.address]);
                setNewAddress({
                    address: '',
                    landmark: '',
                    pincode: '',
                    state: '',
                    city: '',
                    type: ADDRESS_TYPE.PERMANENT,
                    isPrimary: false,
                    isActive: true,
                });
            }
        });
        showToastWithMessage('Address added successfully!');
    };

    return (
        <Card className="my-4" style={{ overflow: 'hidden' }}>
            <Card.Header as="h5">Address Details</Card.Header>
            <Card.Body>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th style={{ width: '7rem' }}>Address</th>
                            <th style={{ width: '10rem' }}>Landmark</th>
                            <th style={{ width: '7rem' }}>Pincode</th>
                            <th style={{ width: '7rem' }}>State</th>
                            <th style={{ width: '8rem' }}>City</th>
                            <th style={{ width: '10rem' }}>Type</th>
                            <th>Is Primary</th>
                            <th>Is Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addresses?.map((address, index) => (
                            <tr key={index}>
                                {editId === address.id ? (
                                    <>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="address"
                                                value={editedAddress?.address ?? ''}
                                                onChange={handleInputChange}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="landmark"
                                                value={editedAddress?.landmark ?? ''}
                                                onChange={handleInputChange}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="pincode"
                                                value={editedAddress?.pincode ?? ''}
                                                onChange={handleInputChange}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                isDisabled={isDisabled}
                                                options={STATES}
                                                value={{
                                                    value: editedAddress?.state,
                                                    label: editedAddress?.state,
                                                }}
                                                onChange={(selectedOption) =>
                                                    setEditedAddress(
                                                        (prev) =>
                                                            prev && {
                                                                ...prev,
                                                                state: selectedOption?.value || '',
                                                            }
                                                    )
                                                }
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                        width: 300,
                                                    }),
                                                }}
                                                menuPlacement="auto"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={editedAddress?.city ?? ''}
                                                onChange={handleInputChange}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                disabled={isDisabled}
                                                className="form-select"
                                                value={editedAddress?.type}
                                                name="type"
                                                onChange={handleInputChange as any}
                                            >
                                                <option value={ADDRESS_TYPE.CURRENT}>
                                                    Current
                                                </option>
                                                <option value={ADDRESS_TYPE.PERMANENT}>
                                                    Permanent
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <Form.Check
                                                disabled={isDisabled}
                                                type="checkbox"
                                                checked={editedAddress?.isPrimary}
                                                onChange={(e) =>
                                                    setEditedAddress(
                                                        (prev) =>
                                                            prev && {
                                                                ...prev,
                                                                isPrimary: e.target.checked,
                                                            }
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Form.Check
                                                disabled={isDisabled}
                                                type="checkbox"
                                                checked={editedAddress?.isActive}
                                                onChange={(e) =>
                                                    setEditedAddress(
                                                        (prev) =>
                                                            prev && {
                                                                ...prev,
                                                                isActive: e.target.checked,
                                                            }
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            {!isDisabled && (
                                                <div className="d-flex">
                                                    <Button variant="success" onClick={saveEdit}>
                                                        OK
                                                    </Button>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={cancelEdit}
                                                        className="mx-2"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{address.address}</td>
                                        <td>{address.landmark}</td>
                                        <td>{address.pincode}</td>
                                        <td>{address.state}</td>
                                        <td>{address.city}</td>
                                        <td>{address.type}</td>
                                        <td>{address.isPrimary ? 'Yes' : 'No'}</td>
                                        <td>{address.isActive ? 'Yes' : 'No'}</td>
                                        <td>
                                            {!isDisabled && (
                                                <div className="d-flex">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => startEdit(address.id!)}
                                                    >
                                                        <BiPencil />
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => deleteAddress(address.id!)}
                                                        className="mx-2"
                                                    >
                                                        <BiTrash />
                                                    </Button>
                                                </div>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Enter Address"
                                    value={newAddress.address}
                                    onChange={handleNewAddressChange}
                                    disabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    name="landmark"
                                    placeholder="Enter Landmark"
                                    value={newAddress?.landmark ?? ''}
                                    onChange={handleNewAddressChange}
                                    disabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    placeholder="Pincode"
                                    value={newAddress.pincode}
                                    onChange={handleNewAddressChange}
                                    disabled={isDisabled}
                                />
                            </td>
                            <td>
                                <Select
                                    isDisabled={isDisabled}
                                    className="w-100"
                                    options={STATES}
                                    value={{ value: newAddress.state, label: newAddress.state }}
                                    onChange={(selectedOption) =>
                                        setNewAddress((prev) => ({
                                            ...prev,
                                            state: selectedOption?.value || '',
                                        }))
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                            width: 300,
                                        }),
                                    }}
                                    menuPlacement="auto"
                                />
                            </td>
                            <td>
                                <Form.Control
                                    disabled={isDisabled}
                                    type="text"
                                    name="city"
                                    placeholder="Enter City"
                                    value={newAddress.city}
                                    onChange={handleNewAddressChange}
                                />
                            </td>
                            <td>
                                <select
                                    disabled={isDisabled}
                                    className="form-select"
                                    value={newAddress.type}
                                    onChange={(e) => {
                                        if (e.target.value)
                                            setNewAddress((prev) => ({
                                                ...prev,
                                                type: e.target.value as ADDRESS_TYPE,
                                            }));
                                    }}
                                >
                                    <option value={ADDRESS_TYPE.CURRENT}>Current</option>
                                    <option value={ADDRESS_TYPE.PERMANENT}>Permanent</option>
                                </select>
                            </td>
                            <td>
                                <Form.Check
                                    disabled={isDisabled}
                                    type="checkbox"
                                    checked={newAddress.isPrimary}
                                    onChange={(e) =>
                                        setNewAddress((prev) => ({
                                            ...prev,
                                            isPrimary: e.target.checked,
                                        }))
                                    }
                                />
                            </td>
                            <td>
                                <Form.Check
                                    disabled={isDisabled}
                                    type="checkbox"
                                    checked={newAddress.isActive}
                                    onChange={(e) =>
                                        setNewAddress((prev) => ({
                                            ...prev,
                                            isActive: e.target.checked,
                                        }))
                                    }
                                />
                            </td>
                            <td>
                                {!isDisabled && (
                                    <Button variant="success" onClick={addNewAddress}>
                                        Add Address
                                    </Button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>

            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'fixed', top: '10px', right: '10px' }}
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Card>
    );
};

export default AddressDetails;
