import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useLoaderData, useParams } from "react-router-dom";
import MainBtn from "../../components/btn/main-btn";
import Levels from "../../components/modals/levels-modal";
import { BACKEND_URLS } from "../../config";
import { axiosClient } from "../../services/axiosClient";
import { LevelsApiInterface } from "../../types/ApiTypes";



export async function loader({
  request,
  params,
}: any): Promise<LevelsApiInterface> {
  const levelId = params.levelId;
  const data = await axiosClient.get(BACKEND_URLS.LEVEL + levelId);
  return data.data;
}


type formTypes = {
  name: string,
  internalName: string,
  imageUrl: string,
  isSubject?: boolean,
  isChapter?: boolean,
  level1Id?: number | null,
  id?: number,
  order?: number
}

interface SubjectInterface {
  id: number,
  name: string,
  internalName: string,
  isSubject: boolean,
  imageUrl: string,
  order: number,
  createdOn: string,
  updatedOn: null | string
}


function SubjectGroups() {
  const data = useLoaderData() as LevelsApiInterface;
  const { levelId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [levelData, setLevelData] = useState<any[]>([])

  const [nodeData, setNodeData] = useState<formTypes | null>(null)

  useEffect(
    () => {
      setLevelData(data.data.level)
    }, [data]
  )

  const EditButtonCell = ({ row, setNodeData, setShowModal }: any) => {
    const handleClick = () => {
      // Populate the state with the row data
      setNodeData({ ...row, name: row.name ? row.name : row.internalName })
      //      {
      //     id: row.id,
      //     imageUrl: row.imageUrl,
      //     internalName: row.internalName,
      //     name: row.name ? row.name : row.internalName,
      //     isChapter: row?.isChapter,
      //     isSubject: row?.isSubject,
      //     level1Id: row?.level1Id,
      //     order: row?.order
      //   }
      // );

      // Open the modal
      setShowModal(true);
    };

    return (
      <button className="btn btn-primary" onClick={handleClick}>
        Edit
      </button>
    );
  };

  // Image Formatter for handling dynamic image URLs
  const ImageCell = (row: any) => {
    const imageUrl = row.imageUrl?.includes('https://myesaralbucket.s3.amazonaws.com/')
      ? row.imageUrl
      : 'https://myesaralbucket.s3.amazonaws.com/' + row.imageUrl;

    return (
      <img
        src={imageUrl}
        alt="Icon"
        style={{
          width: "48px",
          height: "48px",
          objectFit: "cover",
        }}
      />
    );
  };

  // Columns for the main table
  const columns = [
    {
      name: "Icon",
      selector: (row: any) => row.imageUrl,
      cell: ImageCell,
      sortable: true,
    },
    levelId === `${1}` || levelId === '3' ? {
      name: "Order",
      selector: (row: SubjectInterface) => row.order,
      cell: (row: SubjectInterface) => <Form.Control
        value={row.order}
        onChange={(e) => {
          setLevelData(
            (pervData) => pervData.map(
              (data) => data.id === row.id ? { ...data, order: e.target.value } : data
            )
          )
        }}
        onBlur={(e) => {
          const res = axiosClient.put(BACKEND_URLS.LEVEL + levelId + `/` + row.id, {
            "order": e.target.value
          }).then(
            (res) => {
              if (res.data.status === "success") {
                toast.success(res.data.message)
              }
              else {
                toast.error(res.data.message)
              }
            }
          ).catch(
            (err) => {
              toast.error(err.message)
            }
          )
        }}
        className='text-breaker'
      />,
      sortable: true,
    }
      : {},
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.name} </div>
    },
    {
      name: "Internal Name",
      selector: (row: any) => row.internalName,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.internalName} </div>
    },
    {
      name: "Created On",
      selector: (row: any) => row.createdOn?.split('T')[0],
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.createdOn?.split('T')[0]} </div>
    },
    {
      name: "Updated On",
      selector: (row: any) => row.updatedOn?.split('T')[0],
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.updatedOn?.split('T')[0]} </div>
    },
    {
      name: 'Action',
      cell: (row: any) => (
        <EditButtonCell row={row} setNodeData={setNodeData}
          setShowModal={setShowModal} />
      ),
      ignoreRowClick: true, 
      allowoverflow: true,
      button: true,
      sortable: true,
    },
  ];

  // Columns for the chapter table (when levelId == "2")
  const chapterColumns = [
    {
      name: "Icon",
      selector: (row: any) => row.imageUrl,
      cell: ImageCell,
      sortable: true,
    },
    {
      name: "Internal Name",
      selector: (row: any) => row.internalName,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.internalName} </div>
    },
    {
      name: "Subject",
      selector: (row: any) => row.level1Name,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.level1Name} </div>
    },
    {
      name: "Created On",
      selector: (row: any) => row.createdOn?.split('T')[0],
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.createdOn?.split('T')[0]} </div>
    },
    {
      name: "Updated On",
      selector: (row: any) => row.updatedOn?.split('T')[0],
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.updatedOn?.split('T')[0]} </div>
    },
    {
      name: 'Action',
      cell: (row: any) => (
        <EditButtonCell row={row} setNodeData={setNodeData} setShowModal={setShowModal} />
      ),
      ignoreRowClick: true, // Prevents the row click event when button is clicked
      allowoverflow: true,
      button: true,
      sortable: true,
    },
  ];



  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setNodeData(null)
    setShowModal(false)
  }

  const handleSubmit = async (formData: formTypes) => {
    if (levelId == `${0}`) {
      try {
        let response;

        if (nodeData) {
          response = await axiosClient.put(BACKEND_URLS.LEVEL + levelId + `/` + nodeData.id, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
          })
        }
        else {
          response = await axiosClient.post(BACKEND_URLS.LEVEL + levelId, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
          })
        }

        toast.error(response.data.message)
      } catch (error: any) {
        toast.error(error)
      }
    }

    if (levelId == `${1}`) {
      try {
        let response: any;

        if (nodeData) {
          response = await axiosClient.put(BACKEND_URLS.LEVEL + levelId + `/` + nodeData.id, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
            "isSubject": formData.isSubject,
            "order": formData.order,
          })
          setLevelData(
            (pervData) => pervData.map(
              (data) => data.id === nodeData.id ? { ...data, order: formData.order } : data
            )
          )
        }
        else {
          response = await axiosClient.post(BACKEND_URLS.LEVEL + levelId, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
            "isSubject": formData.isSubject,
            "order": formData.order,
          })
          setLevelData(
            (pervData) => ([...pervData, {
              ...formData,
              //@ts-ignore
              id: response.data.data.id
            }])
          )
        }


        toast.success(response.data.message)
      } catch (error: any) {
        toast.error(error)
      }
    }

    if (levelId == `${3}`) {
      try {
        let response;

        if (nodeData) {
          response = await axiosClient.put(BACKEND_URLS.LEVEL + levelId + `/` + nodeData.id, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl || undefined,
          })
        }
        else {
          response = await axiosClient.post(BACKEND_URLS.LEVEL + levelId, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl || undefined,
          })
        }

        toast.success(response.data.message)
      } catch (error: any) {
        toast.error(error)
      }
    }

    if (levelId == `${2}`) {
      try {
        let response;
        if (nodeData) {
          response = await axiosClient.put(BACKEND_URLS.LEVEL + levelId + `/` + nodeData.id, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
            "level1Id": formData.level1Id,
            "isChapter": true
          })
        }
        else {
          response = await axiosClient.post(BACKEND_URLS.LEVEL + levelId, {
            "name": formData.name,
            "internalName": formData.internalName,
            "imageUrl": formData.imageUrl,
            "level1Id": formData.level1Id,
            "isChapter": true
          })
        }
        toast.success(response.data.message)
      } catch (error: any) {
        toast.error(error)
      }
    }
  };

  return (
    <>
      <div className="contanier mx-3">
        <h2>
          {levelId == "0"
            ? `Subject Groups`
            : levelId == "1"
              ? "Subjects"
              : levelId == "3"
                ? "Content Groups"
                : levelId == "2"
                  ? "Chapters"
                  : ""}
        </h2>
        <DataTable
          columns={levelId === "2" ? chapterColumns : columns}
          data={levelData}
          pagination
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 500, 1000]}
          responsive
          customStyles={{
            table: {
              style: {
                width: '90%',
                margin: 'auto'
              }
            }
          }}
        />
      </div>
      {
        levelId &&
        <Levels nodeData={nodeData} show={showModal} handleSubmit={handleSubmit} handleClose={handleClose} level={levelId} />
      }
      <MainBtn
        message="make new subject group"
        onClick={handleShow}
      />
    </>
  );
}

export default SubjectGroups;
